<template>
    <div class="activatesetting">
        <el-descriptions :style="objectStyle" title="激活信息" :column="3" border>
            <el-descriptions-item label="激活状态" label-class-name="my-activate-label" content-class-name="my-activate-content">{{activateinfo.state === 1 ? "永久": activateinfo.state === 0 ? "试用" : "未注册"}}</el-descriptions-item>
            <el-descriptions-item label="试用天数" label-class-name="my-time-label" content-class-name="my-time-content">{{activateinfo.state !== 0 ? "----" : activateinfo.time + '天'}}</el-descriptions-item>
            <el-descriptions-item label="起始时间" label-class-name="my-startdate-label" content-class-name="my-startdate-content">{{activateinfo.state !== 0 ? "----" : activateinfo.startdate}}</el-descriptions-item>
            <el-descriptions-item label="结束时间" label-class-name="my-enddate-label" content-class-name="my-enddate-content">{{activateinfo.state !== 0 ? "----" : activateinfo.enddate}}</el-descriptions-item>
            <el-descriptions-item label="SSID码" label-class-name="my-ssid-label" content-class-name="my-ssid-content">{{activateinfo.state !== 2 ? "----" : activateinfo.permanentcode}}</el-descriptions-item>
        </el-descriptions>
    </div>
</template>

<script>
export default {
  data() {
      return {
          objectStyle: {
              width: this.windowWidth
          }
      }
  },
  props: {
    activateinfo: {}
  },
  watch: {
     windowHeight (val) {
      let that = this;
      console.log("实时屏幕高度：", val, that.windowHeight);
      setTimeout(()=> {
           this.refreshStyle()
        }, 100)
    },
    windowWidth (val) {
      let that = this;
      console.log("实时屏幕宽度：", val, that.windowHeight);
      setTimeout(()=> {
           this.refreshStyle()
        }, 100)
    },
    
    activateinfo: {
      immediate: true, // 很重要！！！
      handler (val) {
        //console.log('refamenddata:', val)
        setTimeout(()=> {
           this.refreshStyle()
        }, 100)
        
      }
    }
  },
  computed: {
    windowWidth () {
      return this.$store.state.tab.windowWidth
    },
    windowHeight () {
      return this.$store.state.tab.windowHeight
    }
  },
  methods: {
    refreshStyle() {
      var activate = document.querySelector('.activatesetting')
      activate.style.height = 33 + 'vh'

      this.objectStyle.width = 60 + 'vw'
      //console.log('this.objectStyle.width', this.objectStyle.width)
    }
  }
}
</script>


<style lang="scss" scoped>
.activatesetting ::v-deep {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .my-activate-label,
    .my-time-label,
    .my-startdate-label,
    .my-enddate-label,
    .my-ssid-label {
      color: #1f1f1f;
      text-align: center;
  }

  .el-descriptions {
    box-sizing: border-box;
    font-size: 14px;
    color: lemonchiffon;
}

  .my-activate-content {
    color:orangered;
    text-align: center;
  }

  .my-time-content {
    color:darkcyan;
    text-align: center;
  }

  .my-startdate-content {
    color:green;
    text-align: center;
  }

  .my-enddate-content {
    color:brown;
    text-align: center;
  }

  .my-ssid-content {
    color: blue;
    text-align: center;
  }
}
</style>