<template>
	<div class="powermeter_info">
        <el-tabs tab-position="top">
            <el-tab-pane label="电压电流频率">
                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">相电压A</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-green-dark">{{parseFloat(powermeterdata.avolt).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">V</div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">相电压B</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-green-dark">{{parseFloat(powermeterdata.bvolt).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">V</div></el-col>
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">相电压C</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-black-dark">{{parseFloat(powermeterdata.cvolt).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">V</div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">线电压AB</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-black-dark">{{parseFloat(powermeterdata.abvolt).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">V</div></el-col>
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">线电压CA</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-blue-dark">{{parseFloat(powermeterdata.cavolt).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">V</div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">线电压BC</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-blue-dark">{{parseFloat(powermeterdata.bcvolt).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">V</div></el-col>
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">频率</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-red-dark">{{parseFloat(powermeterdata.freq).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">Hz</div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">零序电流</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-red-dark">{{parseFloat(powermeterdata.zsc).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">A</div></el-col>
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">相电流A</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-blue-dark">{{parseFloat(powermeterdata.acurr).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">A</div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">相电流B</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-blue-dark">{{parseFloat(powermeterdata.bcurr).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">A</div></el-col>
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">相电流C</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-orange-dark">{{parseFloat(powermeterdata.ccurr).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">A</div></el-col>
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                </el-row>
            </el-tab-pane>

            <el-tab-pane label="电能">
                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">正向有功电能</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-green-dark">{{parseFloat(powermeterdata.ppae).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">KWH</div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">正向无功电能</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-green-dark">{{parseFloat(powermeterdata.ppre).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">KVARH</div></el-col>
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">反向有功电能</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-black-dark">{{parseFloat(powermeterdata.npae).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">KWH</div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">反向无功电能</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-black-dark">{{parseFloat(powermeterdata.npre).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">KVARH</div></el-col>
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">电压不平衡度</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-blue-dark">{{parseFloat(powermeterdata.unbv).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">%</div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">电流不平衡度</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-blue-dark">{{parseFloat(powermeterdata.unbc).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">%</div></el-col>
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                </el-row>
            </el-tab-pane>

            <el-tab-pane label="功率/功率因数">
                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">有功功率A</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-green-dark">{{parseFloat(powermeterdata.aap).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">KW</div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">有功功率B</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-green-dark">{{parseFloat(powermeterdata.bap).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">KW</div></el-col>
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">有功功率C</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-black-dark">{{parseFloat(powermeterdata.cap).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">KW</div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">有功功率ABC</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-black-dark">{{parseFloat(powermeterdata.tap).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">KW</div></el-col>
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">无功功率A</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-blue-dark">{{parseFloat(powermeterdata.arp).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">KVAR</div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">无功功率B</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-blue-dark">{{parseFloat(powermeterdata.brp).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">KVAR</div></el-col>
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">无功功率C</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-red-dark">{{parseFloat(powermeterdata.crp).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">KVAR</div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">无功功率ABC</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-red-dark">{{parseFloat(powermeterdata.trp).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">KVAR</div></el-col>
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">视在功率A</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-blue-dark">{{parseFloat(powermeterdata.aappp).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">KVA</div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">视在功率B</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-blue-dark">{{parseFloat(powermeterdata.bappp).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">KVA</div></el-col>
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">视在功率C</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-orange-dark">{{parseFloat(powermeterdata.cappp).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">KVA</div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">视在功率ABC</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-orange-dark">{{parseFloat(powermeterdata.tappp).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">KVA</div></el-col>
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">功率因数A</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-black-dark">{{parseFloat(powermeterdata.apf).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">P/S</div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">功率因数B</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-black-dark">{{parseFloat(powermeterdata.bpf).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">P/S</div></el-col>
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">功率因数C</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-green-dark">{{parseFloat(powermeterdata.cpf).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">P/S</div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">功率因数ABC</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-green-dark">{{parseFloat(powermeterdata.tpf).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">P/S</div></el-col>
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                </el-row>
            </el-tab-pane>

            <el-tab-pane label="谐波含量">
                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">电压总谐波含量A</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-green-dark">{{parseFloat(powermeterdata.apvthd).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">电压总谐波含量B</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-green-dark">{{parseFloat(powermeterdata.bpvthd).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">电压总谐波含量C</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-black-dark">{{parseFloat(powermeterdata.cpvthd).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">电流总谐波含量A</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-black-dark">{{parseFloat(powermeterdata.apcthd).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">电流总谐波含量B</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-blue-dark">{{parseFloat(powermeterdata.bpcthd).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="5"><div class="powermeter_label bg-purple-light">电流总谐波含量C</div></el-col>
                    <el-col :span="2"><div class="powermeter_value bg-blue-dark">{{parseFloat(powermeterdata.cpcthd).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="powermeter_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="3"><div class="powermeter_label"></div></el-col>
                </el-row>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
	export default {
		props: {
			powermeterdata: {}
		},

        watch: {
            windowHeight (val) {
            let that = this;
            console.log("实时屏幕高度：", val, that.windowHeight);
            this.refreshStyle()
            },
            windowWidth (val) {
            let that = this;
            console.log("实时屏幕宽度：", val, that.windowHeight);
            this.refreshStyle()
            },
            powermeterdata: {
				immediate: true, // 很重要！！！
				handler (val) {
					//console.log('powermeterdata:', val)
                    this.refreshStyle()
				}
			}
        },
        computed: {
            windowWidth () {
            return this.$store.state.tab.windowWidth
            },
            windowHeight () {
            return this.$store.state.tab.windowHeight
            }
        },
        methods: {
            refreshStyle() {
                var PowermeterInfo = document.querySelectorAll('.powermeter_info')
                for(var i = 0; i < PowermeterInfo.length; i++)
                {
                    PowermeterInfo[i].style.height = 55  + 'vh'
                }
            }
        }
	}
</script>

<style lang="scss" scoped>
	.el-row {
    margin-bottom: 0.156vw;
    &:last-child {
      margin-bottom: 0.156vw;
    }
  }
  .el-col {
    border-radius: 0.208vw;
  }
  .bg-purple-dark {
    background: transparent;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: transparent;
  }
  .bg-green-dark {
    background: darkgreen;
    color: white;
  }
  .bg-black-dark {
    background-color: #1F1F1F;
    color: #DBE276;
  }
  .bg-orange-dark {
    background: orangered;
    color: white;
  }
  .bg-blue-dark {
    background-color: darkcyan;
    color: white;
  }

  .bg-red-dark {
    background: red;
    color: white;
  }
  .bg-violet-dark {
    background-color: violet;
    color: white;
  }
</style>

<style lang="scss" scoped>
    
.powermeter_info {
	padding: 0.156vw;
	border: 0.052vw solid #ccc;
	
    .powermeter_label,
    .powermeter_unit {
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 0.833vw;
        color: #fff;
        min-height: 1.563vw;
        max-height: 2.083vw;
        text-align: center;
        border-radius: 0.26vw;
    }
    .powermeter_value {
        border-radius: 0.26vw;
        border: 0.052vw solid brown;
        min-height: 1.563vw;
        max-height: 2.083vw;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 0.833vw;
    }    
}

input[type="number"]{
    border: 0.052vw solid; border-color: #EEE #CCC #CCC #EEE;
    color: #000; font-weight: bold; background: #F5F5F5;
}
	
</style>