<template>

  <el-container>
    <el-aside width="auto">
      <span class="myinput">
        <el-input placeholder="输入关键字进行过滤" v-model="filterText">
        </el-input>
      </span>
      <span class="mytree">
        <el-tree v-if="loadFinish === true" :highlight-current="highlightCurrent" :data="data" :props="defaultProps"
          @node-click="handleNodeClick" ref="tree" accordion :filter-node-method="filterNode"></el-tree>
      </span>
    </el-aside>
    <el-main>
      <span class="mytab">
        <el-tabs tab-position="left">
          <el-tab-pane label="通道主页">
            <el-container>
              <el-main>
                <div v-if="chfilterInfo.length > 0">
                  <div v-for="(item,index) in chfilterInfo" :key="index">
                    <channel-detail :chdata="item" :comdata="commonInfo" :channelid="item.id" :style="styleObject"
                      :totalChCount="totalChCount"></channel-detail>
                  </div>
                </div>

                <div class="mytable" v-if="chnoctlInfo.length > 0">
                  <el-table :data="chnoctlInfo" style="width: 100%" border highlight-current-row>
                    <el-table-column align="center" header-align="center" prop="chname" label="辅助通道名称">
                    </el-table-column>
                    <el-table-column align="center" header-align="center" prop="chpv" label="实时值">
                    </el-table-column>
                    <el-table-column align="center" header-align="center" prop="chunit" label="单位">
                    </el-table-column>
                  </el-table>
                </div>
              </el-main>
              <el-aside width="auto">
                <common-detail :comdata="commonInfo" v-if="chfilterInfo.length > 0 && commonInfo.devicetypemodel === 1"></common-detail>
                <shockcommon-detail :comdata="commonInfo" v-if="chfilterInfo.length > 0 && commonInfo.devicetypemodel === 2"></shockcommon-detail>
                <div class="button_style" v-if="chfilterInfo.length > 0">
                  <el-row :span="24" :gutter="10">
                    <el-col :span="12">
                      <div class="bg-purple button_label">温箱启停</div>
                    </el-col>
                  </el-row>
                  <el-row :span="24" :gutter="10">
                    <el-col :span="8">
                      <el-button size="medium" type="success"
                        @click="commonInfo.devstate !== 1 ? stopDevice() : startDevice()">
                        {{commonInfo.devstate !== 1 ? '停止' : '启动'}}</el-button>
                    </el-col>
                    <el-col v-show="commonInfo.devstate !== 1" :span="8">
                      <el-button size="medium" type="warning" @click="pauseDevice()">
                        {{commonInfo.devstate !== 3 ? '暂停' : "继续"}}</el-button>
                    </el-col>
                    <el-col v-show="commonInfo.devstate !== 1" :span="8">
                      <el-button size="medium" type="danger" @click="jumpDevice()">跳段</el-button>
                    </el-col>
                  </el-row>
                </div>
              </el-aside>
            </el-container>
          </el-tab-pane>
          <el-tab-pane label="压缩机电表">
            <compressor-detail :compressordata="compressorinfo" v-if="chfilterInfo.length > 0"></compressor-detail>
            <powermeter-detail :powermeterdata="powermeterinfo" v-if="chfilterInfo.length > 0"></powermeter-detail>
          </el-tab-pane>
          <el-tab-pane label="通道修正">
            <div v-for="item in chInfo" :key="item.id">
              <channel-amend :chdata="item" :chinfo="chInfo" :amenddata="mainamendinfo" :refamenddata="refamendinfo" v-if="chfilterInfo.length > 0">
              </channel-amend>
            </div>
          </el-tab-pane>
          <el-tab-pane label="通道PID">
            <div v-for="item in chInfo" :key="item.id">
              <pid-detail :piddata="pidinfo.filter(pid=>pid.chid == item.id)"
                :pidrangedata="pidrangeinfo.filter(pidrange=>pidrange.chid == item.id)" :chinfo="chInfo" :chdata="item" v-if="chfilterInfo.length > 0">
              </pid-detail>
            </div>
          </el-tab-pane>
          <el-tab-pane label="预约激活">
            <startorder-detail :startorderinfo="startorderinfo" v-if="chfilterInfo.length > 0"></startorder-detail>
            <stoporder-detail :stoporderinfo="stoporderinfo" v-if="chfilterInfo.length > 0"></stoporder-detail>
            <activate-detail :activateinfo="activateinfo" v-if="chfilterInfo.length > 0"></activate-detail>
          </el-tab-pane>
          <el-tab-pane label="TH信号">
            <tonoffdata-detail :tonoffdata="tonoffdatainfo" v-if="chfilterInfo.length > 0"></tonoffdata-detail>
            <honoffdata-detail :honoffdata="honoffdatainfo" v-if="chfilterInfo.length > 0"></honoffdata-detail>
          </el-tab-pane>
          <el-tab-pane label="ADD信号">
            <additionalonoffdata-detail :dataArray="additionalonoffdatainfo" v-if="chfilterInfo.length > 0"></additionalonoffdata-detail>
          </el-tab-pane>
          <el-tab-pane label="IS信号">
            <isdata-detail :isdatainfo="isdatainfo" :chinfo="chInfo" v-if="chfilterInfo.length > 0"></isdata-detail>
          </el-tab-pane>
        </el-tabs>
      </span>
    </el-main>
  </el-container>
</template>

<script>
import ChannelDetail from '../../src/components/ChannelDetail.vue'
import CommonDetail from '../../src/components/CommonDetail.vue'
import CompressorDetail from '../../src/components/CompressorDetail.vue'
import PowermeterDetail from '../../src/components/PowermeterDetail.vue'
import ChannelAmend from '../../src/components/ChannelAmend.vue'
import PidDetail from '../../src/components/PidDetail.vue'
import StartorderDetail from '../../src/components/StartorderDetail.vue'
import StoporderDetail from '../../src/components/StoporderDetail.vue'
import ActivateDetail from '../../src/components/ActivateDetail.vue'
import TonoffdataDetail from '../../src/components/TonoffdataDetail.vue'
import HonoffdataDetail from '../../src/components/HonoffdataDetail.vue'
import AdditionalonoffdataDetail from '../../src/components/AdditionalonoffdataDetail.vue'
import IsdataDetail from '../../src/components/IsdataDetail.vue'
import ShockcommonDetail from '../../src/components/ShockcommonDetail.vue'
import { getTitleNoList, getDeviceInfo, setDeviceStart } from '../../axios/data.js'
export default {
  data () {
    return {
      chCount: 0,
      chData: [],
      timerId: undefined,
      titlenoList: [],
      data: [],
      loadFinish: false,
      chInfo: [],
      chfilterInfo: [],
      chnoctlInfo: [],
      commonInfo: {},
      compressorinfo: {},
      powermeterinfo: {},
      mainamendinfo: [],
      refamendinfo: [],
      pidinfo: [],
      pidrangeinfo: [],
      startorderinfo: [],
      stoporderinfo: [],
      activateinfo: [],
      tonoffdatainfo: [],
      honoffdatainfo: [],
      additionalonoffdatainfo: [],
      isdatainfo: [],
      alignFormat: 'right',
      clearable: false,
      editable: false,
      loading: null,
      loadText: '',
      filterText: '',
      highlightCurrent: true,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      totalChCount: 1,
      styleObject: {
        height: '40vh'
      },
      refreshInterval: 10000
    }
  },
  components: {
    ChannelDetail,
    CommonDetail,
    CompressorDetail,
    PowermeterDetail,
    ChannelAmend,
    PidDetail,
    StartorderDetail,
    StoporderDetail,
    ActivateDetail,
    TonoffdataDetail,
    HonoffdataDetail,
    AdditionalonoffdataDetail,
    IsdataDetail,
    ShockcommonDetail
  },
  watch: {
    filterText (val) {
      if (this.$refs.tree !== undefined) {
        this.$refs.tree.filter(val);
      }
    },
    windowHeight (val) {
      let that = this;
      console.log("实时屏幕高度：", val, that.windowHeight);
    },
    windowWidth (val) {
      let that = this;
      console.log("实时屏幕宽度：", val, that.windowHeight);
    }
  },
  computed: {
    domain () {
      return this.$store.state.tab.domain
    },
    domain443 () {
      return this.$store.state.tab.domain443
    },
    windowWidth () {
      return this.$store.state.tab.windowWidth
    },
    windowHeight () {
      return this.$store.state.tab.windowHeight
    },
    titleno () {
      return this.$store.state.tab.titleno
    },
    titlelist () {
      return this.$store.state.user.titlelist
    }
  },
  methods: {
    checkStar(titleList) {
        return titleList.indexOf("*") !== -1
    },
    openFullScreen () {
      this.loading = this.$loading({
        lock: true,
        text: this.loadText,
        spinner: 'el-icon-loading',
        customClass: 'create-isLoading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    closeFullScreen () {
      this.loading.close();
    },
    async getTitleList () {
      this.loadText = '正在向服务器请求数据, 请稍候~~'
      this.openFullScreen()
      try {
        const res = await getTitleNoList(this.domain)
        var respText = JSON.parse(res.request.responseText);
        console.log(respText)
        if (respText.status === "true") {
          var isArray = respText.message.constructor === Array
          if (isArray) {
            for (var i = 0; i < respText.message.length; i++) {
              let isvalid = this.checkStar(this.titlelist)
              if(!isvalid)
              {
                 isvalid = this.titlelist.indexOf(respText.message[i].titleno) !== -1
              }
              if(isvalid)
              {
                 this.titlenoList.push(respText.message[i].titleno)
              }
            }
          }
          else {
            var isObject = respText.message.constructor === Object
            if (isObject) {
              let isvalid = this.checkStar(this.titlelist)
              if(!isvalid)
              {
                 isvalid = this.titlelist.indexOf(respText.message.titleno) !== -1
              }
              if(isvalid)
              {
                 this.titlenoList.push(respText.message.titleno)
              }
            }
          }
        }

        for (i = 0; i < this.titlenoList.length; i++) {
          const yearstring = this.titlenoList[i].substring(0, 4)
          const year = parseInt(yearstring)
          if (year >= 2000 && year <= 2100) {
            var isexist = false, j = 0
            for (j = 0; j < this.data.length; j++) {
              if (this.data[j].label === yearstring) {
                isexist = true
                break
              }
            }

            if (!isexist) {
              var obj = {
                label: yearstring,
                year: yearstring,
                children: [{
                  label: this.titlenoList[i],
                  year: yearstring,
                  children: []
                }]
              }
              this.data.push(obj)
            }
            else {
              obj = {
                label: this.titlenoList[i],
                year: yearstring,
                children: []
              }
              this.data[j].children.push(obj)
            }
          }
        }
        this.closeFullScreen()
      } catch (error) {
        console.log(error)
        this.closeFullScreen()
      }
      this.loadFinish = true

      if (this.titleno === '') {
        if (this.data.length > 0) {
          if (this.data[0].children && this.data[0].children.length > 0) {
            var titleno = this.data[0].children[0].label
            //this.titleno = titleno
            this.$store.commit('updateTitleNo', titleno)
            this.filterText = titleno
            await this.getRunInfo(titleno)
          }
        }
      }
      else {
        await this.getRunInfo(this.titleno)
        this.filterText = this.titleno
      }
    },
    async getRunInfo (titleno) {
      if (this.timerId !== undefined) {
        clearInterval(this.timerId)
      }
      let params = {}
      params.titleno = titleno
      //this.titleno = titleno
      try {
        const res = await getDeviceInfo(this.domain, params)
        var respText = JSON.parse(res.request.responseText);
        this.count = 0
        this.chInfo = []
        if (respText.status === "true") {
          this.count = respText.message.count
          this.chInfo = JSON.parse(respText.message.chinfo)
          this.chfilterInfo = this.chInfo.filter(item => (item.chctlflag === true))
          this.chnoctlInfo = this.chInfo.filter(item => (item.chctlflag === false))
          this.totalChCount = this.chfilterInfo.length
          this.styleObject.height = this.chfilterInfo.length === 1 ? '80vh' : '40vh'
          this.commonInfo = JSON.parse(respText.message.commoninfo)
          this.compressorinfo = JSON.parse(respText.message.compressorinfo)
          this.powermeterinfo = JSON.parse(respText.message.powermeterinfo)
          this.mainamendinfo = JSON.parse(respText.message.mainamendinfo)
          this.refamendinfo = JSON.parse(respText.message.refamendinfo)
          this.pidinfo = JSON.parse(respText.message.pidinfo)
          this.pidrangeinfo = JSON.parse(respText.message.pidrangeinfo)
          this.activateinfo = JSON.parse(respText.message.activateinfo)
          this.startorderinfo = JSON.parse(respText.message.startorderinfo)
          this.stoporderinfo = JSON.parse(respText.message.stoporderinfo)
          this.tonoffdatainfo = JSON.parse(respText.message.tonoffdatainfo)
          this.honoffdatainfo = JSON.parse(respText.message.honoffdatainfo)
          this.additionalonoffdatainfo = JSON.parse(respText.message.additionalonoffdatainfo)
          this.isdatainfo = JSON.parse(respText.message.isdatainfo)

          var date = new Date(this.commonInfo.nowtime * 1000)
          this.commonInfo.nowtime = `${this.pad(date.getFullYear().toString(), 2)}/${this.pad((date.getMonth() + 1).toString(), 2)}/${this.pad(date.getDate().toString(), 2)} ${this.pad(date.getHours().toString(), 2)}:${this.pad(date.getMinutes().toString(), 2)}:${this.pad(date.getSeconds().toString(), 2)}`
          //console.log(this.commonInfo.fixsettime)
          var temp = this.commonInfo.fixsettime.split(".").filter(item => item !== "")
          //console.log(temp)
          if (temp.length === 3) {
            this.commonInfo.fixsettime = `${this.pad(temp[0], 5)}:${this.pad(temp[1], 2)}:${this.pad(temp[2], 2)}`
          }

          temp = this.commonInfo.prefinishtime.split(":").filter(item => item !== "")
          if (temp.length === 6) {
            this.commonInfo.prefinishtime = `${this.pad(temp[0], 4)}/${this.pad(temp[1], 2)}/${this.pad(temp[2], 2)} ${this.pad(temp[3], 2)}:${this.pad(temp[4], 2)}:${this.pad(temp[5], 2)}`
          }


        }

        this.closeFullScreen()
        this.timerId = setInterval(() => {
          this.getRunInfo(this.titleno)
        }, this.refreshInterval)
      } catch (error) {
        this.closeFullScreen()
        this.timerId = setInterval(() => {
          this.getRunInfo(this.titleno)
        }, this.refreshInterval)
      }
    },
    pad (num, n) {
      var len = num.length;
      while (len < n) {
        num = "0" + num;
        len++;
      }
      return num;
    },
    filterNode (value, data) {
      //console.log(data)
      if (!value) return true;
      return data.label.indexOf(value) !== -1 || data.year.indexOf(value) !== -1;
    },
    handleNodeClick (data) {
      if (data.label.length === 4) {
        var year = parseInt(data.label)
        if (year >= 2000 && year <= 2100) return
      }
      if (data.label === this.titleno) return
      console.log("detail store commit")
      this.$store.commit('updateTitleNo', data.label)
      this.getRunInfo(data.label)
    },
    startDevice () {
      this.$confirm(`此操作将远程启动设备 课题号${this.titleno} 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          try {
            var data = new Object()
            data.api = '/api/updatedevicestate'
            data.paras = { "state": 2 }
            data.titleno = this.titleno
            data.curtime = new Date().getTime()
            setDeviceStart(this.domain, data)
              .then(res => {
                this.$message({
                  type: 'success',
                  message: `请求已成功发送到服务器${JSON.stringify(res.data)}`
                });
              })
              .catch(error => {
                this.$message({
                  type: 'fail',
                  message: error
                });
              })
          } catch (error) {
            this.$message({
              type: 'fail',
              message: error
            });
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
    },
    stopDevice () {
      this.$confirm(`此操作将远程停止设备 课题号${this.titleno} 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          try {
            var data = new Object()
            data.api = '/api/updatedevicestate'
            data.paras = { "state": 1 }
            data.titleno = this.titleno
            //console.log(new Date().getTime())
            data.curtime = new Date().getTime()
            setDeviceStart(this.domain, data)
              .then(res => {
                this.$message({
                  type: 'success',
                  message: `请求已成功发送到服务器${JSON.stringify(res.data)}`
                });
              })
              .catch(error => {
                this.$message({
                  type: 'error',
                  message: error
                });
              })
          } catch (error) {
            this.$message({
              type: 'error',
              message: error
            });
          }
        }).catch(() => {
          this.$message({
            type: 'error',
            message: '已取消'
          });
        });
    },
    pauseDevice () {
      this.$confirm(`此操作将远程${this.commonInfo.devstate !== 3 ? '暂停' : '继续'}设备 课题号${this.titleno} 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          try {
            var data = new Object()
            data.api = '/api/updatedevicestate'
            data.paras = { "state": this.commonInfo.devstate === 3 ? 6 : 3 }
            data.titleno = this.titleno
            //console.log(new Date().getTime())
            data.curtime = new Date().getTime()
            setDeviceStart(this.domain, data)
              .then(res => {
                this.$message({
                  type: 'success',
                  message: `请求已成功发送到服务器${JSON.stringify(res.data)}`
                });
              })
              .catch(error => {
                this.$message({
                  type: 'error',
                  message: error
                });
              })
          } catch (error) {
            this.$message({
              type: 'error',
              message: error
            });
          }
        }).catch(() => {
          this.$message({
            type: 'error',
            message: '已取消'
          });
        });
    },
    jumpDevice () {
      this.$confirm(`此操作将远程跳段 课题号${this.titleno} 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          try {
            var data = new Object()
            data.api = '/api/updatedevicestate'
            data.paras = { "state": 5 }
            data.titleno = this.titleno
            data.curtime = new Date().getTime()
            setDeviceStart(this.domain, data)
              .then(res => {
                this.$message({
                  type: 'success',
                  message: `请求已成功发送到服务器${JSON.stringify(res.data)}`
                });
              })
              .catch(error => {
                this.$message({
                  type: 'error',
                  message: error
                });
              })
          } catch (error) {
            this.$message({
              type: 'error',
              message: error
            });
          }
        }).catch(() => {
          this.$message({
            type: 'error',
            message: '已取消'
          });
        });
    }
  },
  mounted () {
    this.getTitleList();
  },
  destroyed () {
    clearInterval(this.timerId);
    console.log("clearInterval");
  }
}
</script>

<style lang='scss' scoped>



.button_style {
  margin-top: 4.5vw;
  padding-left: 15px;
  border: 0.052vw solid aqua;
  border-radius: 0.56vw;
}

.el-aside {
    overflow: auto;
    box-sizing: border-box;
    flex-shrink: 0;
    overflow: hidden;
}


.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}

.button_label {
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 0.833vw;
  margin-left: -15px;
  min-height: 2.083vw;
  max-height: 2.083vw;
  text-align: center;
  border-radius: 0.26vw;
}

.el-button {
  min-width: 5.208vw;
  min-height: 2.083vw;
  max-height: 2.083vw;
  margin-top: 0.521vw;
  margin-bottom: 0.26vw;
}

.el-icon-info {
  margin-right: 0.521vw;
  font-size: 2.083vw;
  transform: translateY(0.26vw);
}
.el-main {
  padding-top: 0px;
  // background-color: #1389bc;
}
.el-container {
  height: 100%;
  background-color: #333;
  border: 1px solid #333;
  margin-top: 0.042vw;
  margin-bottom: 1.042vw;
}

.myinput ::v-deep,
.mytable ::v-deep {
  .el-input {
    margin-top: 20px;
    margin-bottom:20px;
  }

  .el-input__inner {
    font-size: 14px;
    color: #1f1f1f;
    background-color: aqua;
    border: 0.052vw solid red;
    margin-top: 10px;
  }
}

.mytree ::v-deep {
  overflow: hidden;
  
  .el-tree-node {
    position: relative;
    padding-left: 0px;
    // border: 1px solid #333;
    background-color: #fff;
  }
  //节点有间隙，隐藏掉展开按钮就好了,如果觉得空隙没事可以删掉
  .el-tree-node__expand-icon.is-leaf {
    display: none;
  }
  .el-tree .el-tree-node__expand-icon.expanded {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .el-tree-node__label {
    width: auto;
    float: right;
    font-family: “Trebuchet MS”, Arial, Helvetica, sans-serif;
    font-size: 14px;
    margin: 10px;
    color: #1f1f1f;
    line-height: 1.302vw;
    letter-spacing: 0.052vw;
    &:hover {
      color: white;
    }
  }

  .el-tree-node:focus > .el-tree-node__content {
    background-color: pink; //背景色
  }
  .el-tree-node__content {
    &:hover {
      background: lightgreen;
      .el-tree-node__label {
        color: white;
      }
    }
  }

  .el-tree-node__content {
    display: flex;
    align-items: center;
    height: 30px;
    cursor: pointer;
}

  .el-tree .el-icon-caret-right:before {
    content: "\e723";
    font-size: 16pt;
    color: green;
    position: absolute;
    left: -0.042vw;
    top: -0.36vw;
  }
  .el-tree .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
    content: "\e722";
    font-size: 16pt;
    color: green;
    position: absolute;
    left: -0.042vw;
    top: -0.36vw;
  }
}

.mytable ::v-deep {
  
  .el-table .el-table__header-wrapper tr th {
    background-color: rgb(18, 47, 92) !important;
    color: rgb(255, 255, 255);
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-weight: normal;
  }
  .el-table .el-table__row {
    // background-color: rgb(18, 47, 92);
    // color: rgb(255, 255, 255);
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-weight: normal;
  }

  .el-table .el-table__body tr.current-row > td {
    background-color: lightblue !important;
    color: white;
  }
  .el-table .el-table__body tr:hover > td {
    background-color: lightgreen !important;
    color: red;
  }

  .el-table td,
  .el-table th.is-leaf {
    border-bottom: 0.052vw solid #8990a7;
    border-right: 0.052vw solid #8990a7;
  }

  .el-table__header tr,
  .el-table__header th {
    height: 2.083vw;
    padding: 0;
  }
  .el-table__body tr,
  .el-table__body td {
    height: 2.083vw;
    padding: 0;
  }

  .el-table {
    border: 0.052vw solid #1f1f1f;
  }

  .el-table--border::after,
  .el-table--group::after {
    width: 0;
  }
  .el-table::before {
    height: 0;
  }
}

.mytab ::v-deep

{
  .el-tabs__item {
    margin-top: 15px;
    padding: -1px 0.521vw;
    margin-bottom: 15px;
    // border: 1px solid #ccc;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 60px;
    line-height: 5;
    font-size: 12px;
    font-weight: 400;
    background-color: aquamarine;
    color: #333;
    text-align: center;
    border-radius: 10px;
  }

  .el-tabs--left .el-tabs__header.is-left {
    // border: 1px solid #ccc;
    float: left;
    margin-bottom: 0px;
    margin-right: 10px;
    // margin-top: -30px;
}

  .el-tabs__item.is-active {
    height: 60px;
    text-align: center;
    line-height: 5;
    color: green; //批改激活表头字体色彩，默认是蓝色
    background-color: aqua;
    border-radius: 10px;
    border-bottom: 2px solid red;
  }

  .el-tabs__active-bar {
     background-color: transparent;
}

  .el-tabs__item:hover {
    height: 60px;
    text-align: center;
    line-height: 5;
    border-radius: 10px;
    color: orangered; //批改鼠标挪动到表头时候字体色彩，默认淡蓝色
    cursor: pointer; //鼠标挪动到表头时候鼠标款式，默认小手
    background-color: pink;
  }
}
</style>

<style lang="scss">
.create-isLoading {
  .el-loading-spinner {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.7);
    padding: 1.042vw;
    border-radius: 0.208vw;
    width: auto;
    text-align: center;
    position: absolute;

    i {
      color: #eee;
    }

    .el-loading-text {
      color: #dbe276;
      font-size: 0.833vw;
    }
  }
}

</style>
