<template>
  <div :class='totalChCount === 1 ? "top_large_detail" : "top_normal_detail"'>
    <div class="title_detail">
      <el-row :span="24" :gutter="5">
        <el-col :span="4">
          <div class="grid-content bg-purple">
            <div class="detail_chname">
              <div>{{chdata.chname}}({{chdata.chunit}})</div>
            </div>
          </div>
        </el-col>
        <el-col :span="5" v-show="comdata.devstate === 1 && comdata.devmode === 1">
          <div class="space_holder"></div>
        </el-col>
        <el-col
          :span="(comdata.devstate === 1 && comdata.devmode === 1) ? 5 : (comdata.devstate === 1 && comdata.devmode === 0) || (comdata.devstate !== 1 && comdata.devmode === 1) ? 4 : 3">
          <div class="detail_tsv_label bg-purple-light">目标值:</div>
        </el-col>
        <el-col
          :span="(comdata.devstate === 1 && comdata.devmode === 1) ? 5 : (comdata.devstate === 1 && comdata.devmode === 0) || (comdata.devstate !== 1 && comdata.devmode === 1) ? 6 : 4">
          <div class="detail_tsv_value">{{chdata.chtsv.toFixed(parseInt(chdata.chdip))}} {{chdata.chunit}}</div>
        </el-col>
        <el-col :span="5" v-show="comdata.devstate === 1 && comdata.devmode === 1">
          <div class="space_holder"></div>
        </el-col>
        <el-col :span="comdata.devstate === 1 ? 4 : 2" v-show="comdata.devmode === 0">
          <div class="detail_slope_label bg-purple-light">斜率:</div>
        </el-col>
        <el-col :span="comdata.devstate === 1 ? 6 : 4" v-show="comdata.devmode === 0">
          <div class="detail_slope_value">{{chdata.chslope.toFixed(parseInt(chdata.chdip))}} {{chdata.chunit}}/min</div>
        </el-col>
        <el-col :span="comdata.devmode === 0 ? 3 : 4" v-show="comdata.devstate !== 1">
          <div class="detail_pid_label bg-purple-light">输出量:</div>
        </el-col>
        <el-col :span="comdata.devmode === 0 ? 4 : 6" v-show="comdata.devstate !== 1">
          <div class="detail_pid_value">{{chdata.mvfwd.toFixed(parseInt(chdata.chdip))}} |
            {{chdata.mvinv.toFixed(parseInt(chdata.chdip))}} % </div>
        </el-col>
      </el-row>
    </div>

    <div class="detail_despr">
      <el-row :span="24" :gutter="5">
        <el-col :span="6">
          <div class="detail_icon"><i :class='chdata.chtype === 0 ? "iconfont icon-wendu" : "iconfont icon-shidu"'></i>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="detail_pv_value" :style='{color: chdata.chcolors.split("|")[0]}'>
            {{chdata.chpv.toFixed(parseInt(chdata.chdip))}}</div>
        </el-col>
        <el-col :span="6">
          <el-row :span="6" :gutter="5" v-show="comdata.devstate !== 1" >
            <div class="detail_sv_value" :style='{color: chdata.chcolors.split("|")[1]}'>
              {{chdata.chsv.toFixed(parseInt(chdata.chdip))}}</div>
          </el-row>
          <el-row :span="6" :gutter="5" v-show="comdata.devstate !== 1 && chdata.chupdownsoak !== -1">
            <div class="detail_usd_icon">
              <el-button type="success" disabled circle=""><i
                  :class='chdata.chupdownsoak === -1 ? "el-icon-caret-bottom" : "el-icon-caret-top"'></i></el-button>
            </div>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  props: {
    chdata: {},
    comdata: {},
    channelid: Number,
    totalChCount: Number
  },
  computed: {
    domain () {
      return this.$store.state.tab.domain
    },
    domain443 () {
      return this.$store.state.tab.domain443
    },
    windowWidth () {
      return this.$store.state.tab.windowWidth
    },
    windowHeight () {
      return this.$store.state.tab.windowHeight
    }
  },
  watch: {
    totalChCount (val) {
      //console.log('chcount:', val)
      setTimeout(() => {
        this.refreshStyle()
      }, 100)
    },
    windowHeight (val) {
      let that = this;
      console.log("channeldetail实时屏幕高度：", val, that.windowHeight);
      this.refreshStyle()
    },
    windowWidth (val) {
      let that = this;
      console.log("channeldetail实时屏幕宽度：", val, that.windowHeight);
      this.refreshStyle()
    }
  },
  methods: {
    setPvValueStyle () {
      var detailPvValue = document.querySelectorAll('.detail_pv_value')
      for (var i = 0; i < detailPvValue.length; i++) {
        detailPvValue[i].style.fontSize = this.totalChCount !== 1 ? 15 + 'vh' : 15 + 'vh'
        detailPvValue[i].style.marginTop = this.totalChCount !== 1 ? (5 + 'vh') : (20 + 'vh')
        detailPvValue[i].style.marginBottom = this.totalChCount !== 1 ? (5 + 'vh') : (20 + 'vh')
      }
    },
    setDetailIconStyle () {
      var detailIcon = document.querySelectorAll('.detail_icon')
      for (var i = 0; i < detailIcon.length; i++) {
        detailIcon[i].style.marginTop = this.totalChCount !== 1 ? (8 + 'vh') : (20 + 'vh')
        detailIcon[i].style.marginBottom = this.totalChCount !== 1 ? (5 + 'vh') : (20 + 'vh')
        detailIcon[i].style.marginLeft = 3 + 'vw'
      }
    },
    setDetailUSDIconSytle () {
      var detailUSD = document.querySelectorAll('.detail_usd_icon')
      for (var i = 0; i < detailUSD.length; i++) {
        detailUSD[i].style.marginTop = this.totalChCount !== 1 ? (5 + 'vh') : (30 + 'vh')
      }
    },
    setDetailSvSytle () {
      var detailSvValue = document.querySelectorAll('.detail_sv_value')
      for (var i = 0; i < detailSvValue.length; i++) {
        detailSvValue[i].style.paddingTop = this.totalChCount !== 1 ? (5 + 'vh') : (5 + 'vh')
        detailSvValue[i].style.fontSize = this.totalChCount !== 1 ? (2 + 'vw') : (5 + 'vw')
      }
    },
    refreshStyle () {
      this.setPvValueStyle()
      this.setDetailIconStyle()
      this.setDetailUSDIconSytle()
      this.setDetailSvSytle()
    }
  },
  mounted () {
    this.refreshStyle()
  }
  // watch: {
  // 	chdata: {
  // 		immediate: true, // 很重要！！！
  // 		handler (val) {
  // 			//console.log('action Value:', val)
  // 		}
  // 	},
  // 	comdata: {
  // 		immediate: true, // 很重要！！！
  // 		handler (val) {
  // 			//console.log('action Value:', val)
  // 		}
  // 	}
  // }
}
</script>

<style lang="scss">
.el-row {
  margin-bottom: 1.042vw;
  &:last-child {
    margin-bottom: 0.521vw;
  }
}
.el-col {
  border-radius: 0.208vw;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 0.208vw;
  min-height: 1.875vw;
}
.row-bg {
  padding: 0.521vw 0;
  background-color: #f9fafc;
}
</style>

<style lang="scss" scoped>
.space_holder {
  min-width: 7.813vw;
  display: flex;
  font-size: 1.042vw;
  align-items: center;
  justify-content: center;
  min-height: 2.083vw;
  max-height: 2.083vw;
}

.top_large_detail {
  padding: 0.156vw;
  border: 0.052vw solid lightseagreen;
  border-radius: 0.26vw;
  .title_detail {
    margin-top: 0.521vw;
    .detail_chname {
      display: flex;
      font-size: 1.042vw;
      align-items: center;
      justify-content: center;
      min-height: 2.083vw;
      max-height: 2.083vw;
    }

    .detail_tsv_label,
    .detail_slope_label,
    .detail_pid_label {
      align-items: center;
      justify-content: center;
      display: flex;
      font-size: 0.833vw;
      min-height: 2.083vw;
      max-height: 2.083vw;
      text-align: center;
      border-radius: 0.26vw;
    }
    .detail_tsv_value,
    .detail_slope_value,
    .detail_pid_value {
      background-color: #1f1f1f;
      color: #dbe276;
      border-radius: 0.26vw;
      border: 0.052vw solid brown;
      min-height: 2.083vw;
      max-height: 2.083vw;
      text-align: center;
      align-items: center;
      justify-content: center;
      display: flex;
      font-size: 0.938vw;
      font-family: Consolas;
    }
  }

  .detail_despr {
    .detail_icon {
      .icon-wendu {
        color: darkmagenta;
      }
      .icon-shidu {
        color: lightgreen;
      }
    }

    .detail_pv_value {
      font-size: 8.333vw;
      align-items: center;
      justify-content: center;
      display: flex;
      font-family: Consolas;
    }

    .detail_sv_value {
      align-items: center;
      justify-content: center;
      display: flex;
      font-size: 3.125vw;
      font-family: Consolas;
    }

    .detail_usd_icon {
      margin-top: 2.083vw;
    }
    .el-icon-caret-bottom,
    .el-icon-caret-top {
      font-size: 3.125vw;
    }
  }
}

.top_normal_detail {
  margin: 10px;
  padding: 0px;
  border: 0.052vw solid red;
  // background-color: #333;
  border-radius: 0.56vw;
  .title_detail {
    margin-top: 0.521vw;
    .detail_chname {
      display: flex;
      font-size: 1.042vw;
      align-items: center;
      justify-content: center;
      min-height: 2.083vw;
      max-height: 2.083vw;
    }

    .detail_tsv_label,
    .detail_slope_label,
    .detail_pid_label {
      align-items: center;
      justify-content: center;
      display: flex;
      font-size: 0.833vw;
      min-height: 2.083vw;
      max-height: 2.083vw;
      text-align: center;
      border-radius: 0.26vw;
    }
    .detail_tsv_value,
    .detail_slope_value,
    .detail_pid_value {
      background-color: red;
      color: #dbe276;
      border-radius: 0.26vw;
      border: 0.052vw solid brown;
      min-height: 2.083vw;
      max-height: 2.083vw;
      text-align: center;
      align-items: center;
      justify-content: center;
      display: flex;
      font-size: 0.938vw;
      font-family: Consolas;
    }
  }

  .detail_despr {
    .detail_icon {
      .icon-wendu {
        color: darkmagenta;
      }
      .icon-shidu {
        color: lightgreen;
      }
    }

    .detail_pv_value {
      font-size: 6.25vw;
      align-items: center;
      justify-content: center;
      display: flex;
      font-family: Consolas;
    }

    .detail_sv_value {
      align-items: center;
      justify-content: center;
      display: flex;
      font-size: 3.125vw;
      font-family: Consolas;
    }
  }
}

input[type="number"] {
  border: 0.052vw solid;
  border-color: #eee #ccc #ccc #eee;
  color: #000;
  font-weight: bold;
  background: #f5f5f5;
}
</style>