<template>
        <div class="isdatainfo">
            <el-descriptions :style="objectStyle" v-for="item in isdatainfo" :key="item.isid" :title='"IS" + item.isid' :column="3" border>
                <el-descriptions-item label="通道" label-class-name="isdata_label" content-class-name="bg-blue-dark">{{chinfo[item.chid - 1].chname}}</el-descriptions-item>
                <el-descriptions-item label="种类" label-class-name="isdata_label" content-class-name="bg-green-dark">{{item.itemtype === 0 ? "实时值" : item.itemtype === 1 ? "设定值" : "目标值"}}</el-descriptions-item>
                <el-descriptions-item label="范围" label-class-name="isdata_label" content-class-name="bg-orange-dark">{{item.rangetype === 0 ? "范围内" : "范围外"}}</el-descriptions-item>
                <el-descriptions-item label="下限" label-class-name="isdata_label" content-class-name="bg-red-dark">{{parseFloat(item.lower).toFixed(1)}}</el-descriptions-item>
                <el-descriptions-item label="上限" label-class-name="isdata_label" content-class-name="bg-violet-dark">{{parseFloat(item.upper).toFixed(1)}}</el-descriptions-item>
            </el-descriptions>
        </div>
</template>

<script>
	export default {
        data() {
            return {
                objectStyle: {
                    width: this.windowWidth
                }
            }
        },
		props: {
			isdatainfo: [],
            chinfo: []
		},
		watch: {
			isdatainfo: {
				immediate: true, // 很重要！！！
				handler (val) {
					//console.log('isdatainfo:', val)
                    setTimeout(()=> {
                        this.refreshStyle()
                    }, 100)
				}
			},
            windowHeight (val) {
                let that = this;
                console.log("实时屏幕高度：", val, that.windowHeight);
                setTimeout(()=> {
                    this.refreshStyle()
                }, 100)
            },
            windowWidth (val) {
                let that = this;
                console.log("实时屏幕宽度：", val, that.windowHeight);
                setTimeout(()=> {
                    this.refreshStyle()
                }, 100)
            }
		},
        methods: {
            refreshStyle() {
                this.objectStyle.width = 60 + 'vw'
                //console.log('this.objectStyle.width', this.objectStyle.width)
            }
        },
        computed: {
        windowWidth () {
        return this.$store.state.tab.windowWidth
        },
        windowHeight () {
        return this.$store.state.tab.windowHeight
        }
  },
	}
</script>

<style lang="scss">
	.el-row {
    margin-bottom: 0.521vw;
    &:last-child {
      margin-bottom: 0.156vw;
    }
  }
  .el-col {
    border-radius: 0.208vw;
  }

.el-row .el-col  .isdata_info {
    margin: 1.042vw;
    border-radius: 0.26vw;
	padding: 0.521vw;
	border: 0.104vw solid #ccc;
}
</style>

<style lang="scss" scoped>
    

    
.isdatainfo ::v-deep {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    
    .bg-purple-dark {
        color: #99a9bf;
        text-align: center;
    }
    .bg-purple {
        color: #d3dce6;
        text-align: center;
    }
    .bg-purple-light {
        color: #e5e9f2;
        text-align: center;
    }
    .bg-green-dark {
        color: darkgreen;
        text-align: center;
    }
    .bg-black-dark {
        color: #1F1F1F;
        text-align: center;
    }
    .bg-orange-dark {
        color: orangered;
        text-align: center;
    }
    .bg-blue-dark {
        color: darkcyan;
        text-align: center;
    }

    .bg-red-dark {
        color: red;
        text-align: center;
    }
    .bg-violet-dark {
        color: violet;
        text-align: center;
    }

.isdata_label {
    color: #1f1f1f;
    text-align: center;
}
}
</style>