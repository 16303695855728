<template>
  <div class="channel_pid">
     <div class="sub_channel_pid">
         <el-row :span="24" :gutter="10" v-show="chdata.chtype !== 1 && chdata.chtype !== 2">
            <el-col :span="4"><div class="space_holder"></div></el-col>
            <el-col :span="2"><div class="pid_name_label bg-purple-light">通道名称</div></el-col>
            <el-col :span="2"><div class="pid_name_value">{{chdata.chname}}</div></el-col>
            <el-col :span="2"><div class="pid_id_label bg-purple-light">通道ID</div></el-col>
            <el-col :span="2"><div class="pid_id_value">{{chdata.id}}</div></el-col>
            <el-col :span="2"><div class="pid_type_label bg-purple-light">通道单位</div></el-col>
            <el-col :span="2"><div class="pid_type_value">{{chdata.chunit}}</div></el-col>
            <el-col :span="2"><div class="pid_type_label bg-purple-light">通道类型</div></el-col>
            <el-col :span="2"><div class="pid_type_value">{{chdata.chtypedict}}</div></el-col>
            <el-col :span="4"><div class="space_holder"></div></el-col>
        </el-row>
        <el-row :span="24" :gutter="10" v-show="chdata.chtype !== 1 && chdata.chtype !== 2">
            <el-col :span="6"><div class="space_holder"></div></el-col>
            <el-col :span="2"><el-button @click="handleClick" >1</el-button></el-col>
            <el-col :span="2"><el-button type="primary" @click="handleClick">2</el-button></el-col>
            <el-col :span="2"><el-button type="success" @click="handleClick">3</el-button></el-col>
            <el-col :span="2"><el-button type="info"    @click="handleClick">4</el-button></el-col>
            <el-col :span="2"><el-button type="warning" @click="handleClick">5</el-button></el-col>
            <el-col :span="2"><el-button type="danger"  @click="handleClick">6</el-button></el-col>
            <el-col :span="6"><div class="space_holder"></div></el-col>
        </el-row>
        <div v-for="item in pidrangedata" :key="item.chid">
            <el-row :span="24" :gutter="10" v-show="chdata.chtype !== 1 && chdata.chtype !== 2">
                <el-col :span="7"><div class="space_holder"></div></el-col>
                <el-col :span="2"><el-button  circle>{{item.edit1.toFixed(1)}}</el-button></el-col>
                <el-col :span="2"><el-button type="primary" circle>{{item.edit2.toFixed(1)}}</el-button></el-col>
                <el-col :span="2"><el-button type="success" circle>{{item.edit3.toFixed(1)}}</el-button></el-col>
                <el-col :span="2"><el-button type="info"    circle>{{item.edit4.toFixed(1)}}</el-button></el-col>
                <el-col :span="2"><el-button type="warning" circle>{{item.edit5.toFixed(1)}}</el-button></el-col>
                <el-col :span="7"><div class="space_holder"></div></el-col>
            </el-row>
        </div>
     </div>
        
     <div class="sub_ch_pid">
        <el-row :span="24" :gutter="10" v-show="chdata.chtype === 1 || chdata.chtype === 2">
            <el-col :span="4"><div class="space_holder"></div></el-col>
            <el-col :span="2"><div class="pid_name_label bg-purple-light">通道名称</div></el-col>
            <el-col :span="2"><div class="pid_name_value">{{chdata.chname}}</div></el-col>
            <el-col :span="2"><div class="pid_id_label bg-purple-light">通道ID</div></el-col>
            <el-col :span="2"><div class="pid_id_value">{{chdata.id}}</div></el-col>
            <el-col :span="2"><div class="pid_type_label bg-purple-light">通道单位</div></el-col>
            <el-col :span="2"><div class="pid_type_value">{{chdata.chunit}}</div></el-col>
            <el-col :span="2"><div class="pid_type_label bg-purple-light">通道类型</div></el-col>
            <el-col :span="2"><div class="pid_type_value">{{chdata.chtypedict}}</div></el-col>
            <el-col :span="4"><div class="space_holder"></div></el-col>
        </el-row>

        <div v-for="item in pidrangedata" :key="item.id">
            <el-row :span="24" :gutter="10" v-show="chdata.chtype === 1 || chdata.chtype === 2">
                <el-col :span="9"><div class="space_holder"></div></el-col>
                <el-col :span="2"><el-button @click="handleClick">7</el-button></el-col>
                <el-col :span="2"><el-button type="primary" @click="handleClick">8</el-button></el-col>
                <el-col :span="2"><el-button type="success" @click="handleClick">9</el-button></el-col>
                <el-col :span="9"><div class="space_holder"></div></el-col>
            </el-row>
            <el-row :span="24" :gutter="10" v-show="chdata.chtype === 1 || chdata.chtype === 2">
                <el-col :span="14"><div class="space_holder"></div></el-col>
                <el-col :span="3"><el-button type="primary" circle>{{item.edit4.toFixed(1)}}</el-button></el-col>
                <el-col :span="7"><div class="space_holder"></div></el-col>
            </el-row>
            <el-row :span="24" :gutter="10" v-show="chdata.chtype === 1 || chdata.chtype === 2">
                <el-col :span="9"><div class="space_holder"></div></el-col>
                <el-col :span="2"><el-button type="info" @click="handleClick">4</el-button></el-col>
                <el-col :span="2"><el-button type="warning" @click="handleClick">5</el-button></el-col>
                <el-col :span="2"><el-button type="danger" @click="handleClick">6</el-button></el-col>
                <el-col :span="9"><div class="space_holder"></div></el-col>
            </el-row>
            <el-row :span="24" :gutter="10" v-show="chdata.chtype === 1 || chdata.chtype === 2">
                <el-col :span="14"><div class="space_holder"></div></el-col>
                <el-col :span="3"><el-button type="info" circle>{{item.edit3.toFixed(1)}}</el-button></el-col>
                <el-col :span="7"><div class="space_holder"></div></el-col>
            </el-row>
            <el-row :span="24" :gutter="10" v-show="chdata.chtype === 1 || chdata.chtype === 2">
                <el-col :span="9"><div class="space_holder"></div></el-col>
                <el-col :span="2"><el-button @click="handleClick">1</el-button></el-col>
                <el-col :span="2"><el-button type="primary" @click="handleClick">2</el-button></el-col>
                <el-col :span="2"><el-button type="success" @click="handleClick">3</el-button></el-col>
                <el-col :span="9"><div class="space_holder"></div></el-col>
            </el-row>
            
            <el-row :span="24" :gutter="10" v-show="chdata.chtype === 1 || chdata.chtype === 2">
                <el-col :span="10"><div class="space_holder"></div></el-col>
                <el-col :span="2"><el-button type="danger" circle>{{item.edit2.toFixed(1)}}</el-button></el-col>
                <el-col :span="2"><el-button type="success" circle>{{item.edit1.toFixed(1)}}</el-button></el-col>
                <el-col :span="10"><div class="space_holder"></div></el-col>
            </el-row>
        </div>
     </div>

     <el-dialog title="PID信息" :visible.sync="dialogTableVisible" center>
         <div class="mytable">
             <el-table :data="gridData" style="width: 100%" border highlight-current-row>
                <el-table-column align="center" header-align="center"  property="chid" label="通道ID号"></el-table-column>
                <el-table-column align="center" header-align="center" property="groupid" label="PID组号"></el-table-column>
                <el-table-column align="center" header-align="center" property="kp" label="P值"></el-table-column>
                <el-table-column align="center" header-align="center" property="ki" label="I值"></el-table-column>
                <el-table-column align="center" header-align="center" property="kd" label="D值"></el-table-column>
                <el-table-column align="center" header-align="center" :property='chdata.chtype !== 1 && chdata.chtype !== 2 ? "svlo" : "refsvlo"' label="下限"></el-table-column>
                <el-table-column align="center" header-align="center" :property='chdata.chtype !== 1 && chdata.chtype !== 2 ? "svhi" : "refsvhi"' label="上限"></el-table-column>
            </el-table>
         </div>
        
     </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
      return {
          dialogTableVisible: false,
          gridData: []
      }
  },
  props: {
    piddata: [],
    pidrangedata: [],
    chinfo: [],
    chdata: {}
  },
  watch: {
     windowHeight (val) {
      let that = this;
      console.log("实时屏幕高度：", val, that.windowHeight);
      this.refreshStyle()
    },
    windowWidth (val) {
      let that = this;
      console.log("实时屏幕宽度：", val, that.windowHeight);
      this.refreshStyle()
    },
    piddata: {
      immediate: true, // 很重要！！！
      handler (val) {
        //console.log('piddata:', val)
        setTimeout(()=> {
           this.refreshStyle()
        }, 100)
      }
    },
    chdata: {
      immediate: true, // 很重要！！！
      handler (val) {
        //console.log('chdata:', val)
        setTimeout(()=> {
           this.refreshStyle()
        }, 100)
        
      }
    },
    pidrangedata: {
      immediate: true, // 很重要！！！
      handler (val) {
        //console.log('pidrangedata:', val)
        setTimeout(()=> {
           this.refreshStyle()
        }, 100)
      }
    },
  },
  computed: {
    windowWidth () {
      return this.$store.state.tab.windowWidth
    },
    windowHeight () {
      return this.$store.state.tab.windowHeight
    }
  },
  methods: {
    refreshStyle() {
        var chpid = document.querySelector('.channel_pid')
        chpid.style.height = this.chinfo.length > 0 ? (100 / parseFloat(this.chinfo.length) * 0.85 + 'vh') : 40 + 'vh'
    },
    handleClick(evt) {
        console.log(evt)
        var chid = this.chdata.id
        var groupid = parseInt(evt.toElement.innerText)
        this.gridData = []
        this.gridData = this.piddata.filter(item=>(item.chid === chid && item.groupid === groupid))
        console.log(this.gridData)
        this.dialogTableVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 1.042vw;
  &:last-child {
    margin-bottom: 0.156vw;
  }
}
.el-col {
  border-radius: 0.208vw;
}

.space_holder {
  min-width: 7.813vw;
  display: flex;
  font-size: 1.042vw;
  align-items: center;
  justify-content: center;
  min-height: 2.083vw;
  max-height: 2.083vw;
}

.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
</style>

<style lang="scss" scoped>

.channel_pid {
    .pid_name_label,
    .pid_id_label,
    .pid_type_label {
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 0.833vw;
        // min-width: 120px;
        min-height: 1.563vw;
        max-height: 1.563vw;
        text-align: center;
        border-radius: 0.26vw;
    }
    .pid_name_value,
    .pid_id_value,
    .pid_type_value {
        background-color: #1f1f1f;
        color: #dbe276;
        border-radius: 0.26vw;
        border: 0.052vw solid brown;
        min-height: 1.563vw;
        max-height: 1.563vw;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 0.833vw;
    }

    .pid_name_value {
    padding-left: 0.521vw 0.104vw;
    background-color: darkred;
    color: white;
    }

    .pid_id_value {
    padding-left: 0.521vw 0.104vw;
    background-color: green;
    color: white;
    }

    .pid_type_value {
    background-color: darkcyan;
    color: white;
    }
}


.mytable ::v-deep {
    .el-table .el-table__header-wrapper tr th {
        background-color: rgb(18, 47, 92) !important;
        color: rgb(255, 255, 255);
        font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
        font-weight: normal;
    }
    .el-table .el-table__row {
        background-color: rgb(18, 47, 92);
        color: rgb(255, 255, 255);
        font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
        font-weight: normal;
    }

    .el-table .el-table__body td {
        background-color: lightblue !important;
        color: #3A3A3A;
    }
    .el-table .el-table__body tr:hover > td {
        background-color: lightgreen !important;
        color: red;
    }

    .el-table__header tr,
    .el-table__header th {
        height: 2.083vw;
        padding: 0;
    }
    .el-table__body tr,
    .el-table__body td {
        height: 2.083vw;
        padding: 0;
    }

    .el-table {
        margin-top: 0.521vw;
    }

    .el-table th {
        display: table-cell !important;
    }

    .el-table--border::after,
    .el-table--group::after {
        width: 0;
    }
    .el-table::before {
        height: 0;
    }
}

// .mytable /deep/ {
// margin-top: 20px;
// }

input[type="number"] {
  border: 0.052vw solid;
  border-color: #eee #ccc #ccc #eee;
  color: #000;
  font-weight: bold;
  background: #f5f5f5;
}
</style>