<template>
	<div class="additionalonoffdata_info">
        <el-row :span="24" :gutter="10">
            <el-col :span="3"><div class="additionalonoffdata_label"></div></el-col>
            <el-col :span="3"><div class="additionalonoffdata_label"></div></el-col>
            <el-col :span="3"><div class="additionalonoffdata_label bg-green-dark">LSP(1)</div></el-col>
            <el-col :span="3"><div class="additionalonoffdata_label bg-orange-dark">MSP(2)</div></el-col>
            <el-col :span="3"><div class="additionalonoffdata_label bg-blue-dark">HSP(3)</div></el-col>
            <el-col :span="3"><div class="additionalonoffdata_label bg-red-dark">HDV(4)</div></el-col>
            <el-col :span="3"><div class="additionalonoffdata_label bg-black-dark">LDV(5)</div></el-col>
            <el-col :span="3"><div class="additionalonoffdata_label"></div></el-col>
        </el-row>

        <el-row :span="24" :gutter="10" v-for="item in dataArray" :key="item.hid">
            <el-col :span="3"><div class="additionalonoffdata_label"></div></el-col>
            <el-col :span="3"><div :class='item.hid % 2 === 0 ? "additionalonoffdata_label bg-red-light" : "additionalonoffdata_label bg-green-light"'>ADD{{item.hid}}</div></el-col>
            <el-col :span="3"><div class="additionalonoffdata_value">{{parseFloat(item.lsp).toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="additionalonoffdata_value">{{parseFloat(item.msp).toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="additionalonoffdata_value">{{parseFloat(item.hsp).toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="additionalonoffdata_value">{{parseFloat(item.hdv).toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="additionalonoffdata_value">{{parseFloat(item.ldv).toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="additionalonoffdata_label"></div></el-col>
        </el-row>
    </div>
</template>

<script>
	export default {
		props: {
			dataArray: []
		},
		watch: {
			dataArray: {
				immediate: true, // 很重要！！！
				handler (val) {
					//console.log('action Value:', val)
                    setTimeout(()=> {
                        this.refreshStyle()
                    }, 100)
				}
			}
		},
        methods: {
            refreshStyle() {
                var addinfo = document.querySelector('.additionalonoffdata_info')
                addinfo.style.height = 110 + 'vh'
            }
        }
	}
</script>

<style lang="scss" scoped>
	.el-row {
    margin-bottom: 0.521vw;
    &:last-child {
      margin-bottom: 0.156vw;
    }
  }
  .el-col {
    border-radius: 0.208vw;
  }
</style>

<style lang="scss" scoped>
    
.additionalonoffdata_info {
	padding: 0.156vw;
	border: 0.052vw solid #ccc;
	
    .additionalonoffdata_label {
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 0.833vw;
        min-height: 1.563vw;
        max-height: 2.083vw;
        text-align: center;
        border-radius: 0.26vw;
    }

    .bg-purple-dark {
        background: #99a9bf;
    }
    .bg-purple {
        background: #d3dce6;
    }
    .bg-purple-light {
        background: #e5e9f2;
    }
    .bg-green-dark {
        background: darkgreen;
        color: white;
    }
    .bg-green-light {
        color: darkcyan;
    }
    .bg-red-light {
        color: red;
    }
    .bg-black-dark {
        background-color: #1F1F1F;
        color: #DBE276;
    }
    .bg-orange-dark {
        background: orangered;
        color: white;
    }
    .bg-blue-dark {
        background-color: darkcyan;
        color: white;
    }

    .bg-red-dark {
        background: red;
        color: white;
    }
    .bg-violet-dark {
        background-color: violet;
        color: white;
    }
    .additionalonoffdata_value {
        border-radius: 0.26vw;
        border: 0.052vw solid #ccc;
        min-height: 1.563vw;
        max-height: 2.083vw;
        color: #fff;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 0.833vw;
    }    
}
</style>