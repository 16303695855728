<template>
  <div class="common_detail">
    <el-row :span="24" :gutter="10">
      <el-col :span="12">
        <div class="detail_devmode_label bg-purple-light">当前模式</div>
      </el-col>
      <el-col :span="12">
        <div class="detail_devmode_value">{{comdata.devmode === 0 ? "高温室运行" : comdata.devmode === 1 ? "低温室运行" : "高低温冲击"}}</div>
      </el-col>
    </el-row>
    <el-row :span="24" :gutter="10">
      <el-col :span="12">
        <div class="detail_devstate_label bg-purple-light">设备状态</div>
      </el-col>
      <el-col :span="12">
        <div class="detail_devstate_value">{{stateStr}}</div>
      </el-col>
    </el-row>

    <el-row :span="24" :gutter="10">
      <el-col :span="12">
        <div class="detail_localremote_label bg-purple-light">本地远程</div>
      </el-col>
      <el-col :span="12">
        <div class="detail_localremote_value">{{comdata.localremote === 0 ? "本地" : "远程"}}</div>
      </el-col>
    </el-row>

    <el-row :span="24" :gutter="10">
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_progno_label bg-purple-light">程序编号</div>
      </el-col>
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_progno_value">{{comdata.curprog}}</div>
      </el-col>
    </el-row>
    <el-row :span="24" :gutter="10">
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_segno_label bg-purple-light">段次编号</div>
      </el-col>
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_segno_value">{{comdata.curpos === 1 ? comdata.currtseg : comdata.curpos === 2 ? comdata.curhtseg : comdata.curltseg}}</div>
      </el-col>
    </el-row>
    <el-row :span="24" :gutter="10">
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_loopno_label bg-purple-light">循环编号</div>
      </el-col>
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_loopno_value">{{comdata.curpos === 1 ? comdata.currtloop : comdata.curpos === 2 ? comdata.curhtloop : comdata.curltloop}}</div>
      </el-col>
    </el-row>
    <el-row :span="24" :gutter="10">
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_outerloop_label bg-purple-light">外循环</div>
      </el-col>
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_outerloop_value">{{comdata.progloopruncount}}/{{comdata.progloopsetcount}}</div>
      </el-col>
    </el-row>

    <el-row :span="24" :gutter="10">
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_innerloop_label bg-purple-light">内循环</div>
      </el-col>
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_innerloop_value">{{comdata.curpos === 1 ? comdata.innerrtloopruncount : comdata.curpos === 2 ? comdata.innerhtloopruncount : comdata.innerltloopruncount}}
            /{{comdata.curpos === 1 ? comdata.innerrtloopsetcount : comdata.curpos === 2 ? comdata.innerhtloopsetcount : comdata.innerltloopsetcount}}</div>
      </el-col>
    </el-row>

    <el-row :span="24" :gutter="10">
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_innerloop_label bg-purple-light">当前位置</div>
      </el-col>
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_innerloop_value">{{comdata.curpos === 1 ? "常温室" : comdata.curpos === 2 ? "高温室" : "低温室"}}</div>
      </el-col>
    </el-row>

    <el-row :span="24" :gutter="10">
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_innerloop_label bg-purple-light">目标位置</div>
      </el-col>
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_innerloop_value">{{comdata.despos === 1 ? "常温室" : comdata.despos === 2 ? "高温室" : "低温室"}}</div>
      </el-col>
    </el-row>

    <el-row :span="24" :gutter="10">
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_segruntime_label bg-purple-light">段运行时间</div>
      </el-col>
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_segruntime_value">{{this.pad(comdata.curpos === 1 ? comdata.currtsegrunhour.toString() : comdata.curpos === 2 ? comdata.curhtsegrunhour.toString() : comdata.curltsegrunhour.toString(), 2)}}
            :{{this.pad(comdata.curpos === 1 ? comdata.currtsegrunmin.toString() : comdata.curpos === 2 ? comdata.curhtsegrunmin.toString() : comdata.curltsegrunmin.toString(), 2)}}
            :{{this.pad(comdata.curpos === 1 ? comdata.currtsegrunsec.toString() : comdata.curpos === 2 ? comdata.curhtsegrunsec.toString() : comdata.curltsegrunsec.toString(), 2)}}
        </div>
      </el-col>
    </el-row>

    <el-row :span="24" :gutter="10">
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_segsettime_label bg-purple-light">段设定时间</div>
      </el-col>
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_segsettime_value">{{this.pad(comdata.curpos === 1 ? comdata.currtsegsethour.toString() : comdata.curpos === 2 ? comdata.curhtsegsethour.toString() : comdata.curltsegsethour.toString(), 2)}}
            :{{this.pad(comdata.curpos === 1 ? comdata.currtsegsetmin.toString() : comdata.curpos === 2 ? comdata.curhtsegsetmin.toString() : comdata.curltsegsetmin.toString(), 2)}}
            :{{this.pad(comdata.curpos === 1 ? comdata.currtsegsetsec.toString() : comdata.curpos === 2 ? comdata.curhtsegsetsec.toString() : comdata.curltsegsetsec.toString(), 2)}}
        </div>
      </el-col>
    </el-row>

    <el-row :span="24" :gutter="10">
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_segruntime_label bg-purple-light">除霜运行时间</div>
      </el-col>
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_segruntime_value">{{this.pad(comdata.frostrunhour.toString(), 2)}}
            :{{this.pad(comdata.frostrunmin.toString(), 2)}}
            :{{this.pad(comdata.frostrunsec.toString(), 2)}}
        </div>
      </el-col>
    </el-row>

    <el-row :span="24" :gutter="10">
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_segsettime_label bg-purple-light">除霜设定时间</div>
      </el-col>
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_segsettime_value">{{this.pad(comdata.frostsethour.toString(), 2)}}
            :{{this.pad(comdata.frostsetmin.toString(), 2)}}
            :{{this.pad(comdata.frostsetsec.toString(), 2)}}
        </div>
      </el-col>
    </el-row>

    

    <el-row :span="24" :gutter="10">
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_totalruntime_label bg-purple-light">总运行时间</div>
      </el-col>
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_totalruntime_value">{{this.pad(comdata.totalrunhour.toString(), 2)}}:{{this.pad(comdata.totalrunmin.toString(), 2)}}:{{this.pad(comdata.totalrunsec.toString(), 2)}}
        </div>
      </el-col>
    </el-row>

    <el-row :span="24" :gutter="10">
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_innerloop_label bg-purple-light">除霜温度</div>
      </el-col>
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_innerloop_value">{{comdata.frosttemp}}</div>
      </el-col>
    </el-row>

    <el-row :span="24" :gutter="10">
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_innerloop_label bg-purple-light">除霜周期</div>
      </el-col>
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_innerloop_value">{{comdata.frostperiod}}</div>
      </el-col>
    </el-row>

    <el-row :span="24" :gutter="10">
      <el-col :span="12" v-show="comdata.devstate !== 1 && comdata.froststate === 1">
        <div class="detail_innerloop_label bg-purple-light">除霜状态</div>
      </el-col>
      <el-col :span="12" v-show="comdata.devstate !== 1 && comdata.froststate === 1">
        <div class="detail_innerloop_value">{{comdata.frostperiod === 1 ? "除霜中" : "未除霜"}}</div>
      </el-col>
    </el-row>

    <el-row :span="24" :gutter="10">
      <el-col :span="12"
        v-show='comdata.devstate !== 1'>
        <div class="detail_prefinish_label bg-purple-light">预计完成时间</div>
      </el-col>
      <el-col :span="12" v-show="comdata.devstate !== 1">
        <div class="detail_prefinish_value">{{comdata.prefinishtime}}</div>
      </el-col>
    </el-row>

    <el-row :span="24" :gutter="10">
      <el-col :span="12">
        <div class="detail_refreshtime_label bg-purple-light">最近刷新时间</div>
      </el-col>
      <el-col :span="12">
        <div class="detail_refreshtime_value">{{comdata.nowtime}}</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
      return {
          stateStr: ''
      }
  },
  props: {
    comdata: {}
  },
  watch: {
     windowHeight (val) {
      let that = this;
      console.log("实时屏幕高度：", val, that.windowHeight);
      this.refreshStyle()
    },
    windowWidth (val) {
      let that = this;
      console.log("实时屏幕宽度：", val, that.windowHeight);
      this.refreshStyle()
    },
    comdata: {
      immediate: true, // 很重要！！！
      handler (val) {
        console.log('watchshockcomdata:', val)
        setTimeout(()=> {
            this.refreshStyle()
            this.refreshRunState()
        }, 500)
      }
    }
  },
  computed: {
    windowWidth () {
      return this.$store.state.tab.windowWidth
    },
    windowHeight () {
      return this.$store.state.tab.windowHeight
    }
  },
  methods: {
    refreshStyle() {
      var CommonDetail = document.querySelectorAll('.common_detail')
      for(var i = 0; i < CommonDetail.length; i++)
      {
          CommonDetail[i].style.width = 20 + 'vw'
      }
    },
    pad(num, n) {
      var len = num.toString().length;
      while(len < n) {
        num = "0" + num;
        len++;
      }
      return num;
    },
    refreshRunState() {
      if(this.comdata.devalarm === "0:0")
      {
        if(this.comdata.devstate !== 1)
        {
            if(this.comdata.devmode === 2)
            {
                if(this.comdata.froststate !== 1)
                {
                    if (this.comdata.devstate === 2)
                    {
                        if(this.comdata.despos === 1)
                        {
                            this.stateStr = "常温室运行"
                        }
                        else if(this.comdata.despos === 3)
                        {
                            this.stateStr = "低温室运行"
                        }
                        else
                        {
                            this.stateStr = "高温室运行"
                        }
                    }
                    else if(this.comdata.devstate === 4)
                    {
                        if(this.comdata.despos === 1)
                        {
                            if(this.comdata.htwaitready === 1 && this.comdata.ltwaitready === 1)
                            {
                                this.stateStr = "高低温室预热遇冷中"
                            }
                            else if(this.comdata.htwaitready === 1 && this.comdata.ltwaitready === 0)
                            {
                                this.stateStr = "高温室预热中"
                            }
                            else if(this.comdata.htwaitready === 0 && this.comdata.ltwaitready === 1)
                            {
                                this.stateStr = "低温室遇冷中"
                            }
                            else
                            {
                                this.stateStr = "常温室等待"
                            }
                        }
                        else if(this.comdata.despos === 3)
                        {
                            if(this.comdata.htwaitready === 1 && this.comdata.ltwaitready === 1)
                            {
                                this.stateStr = "高低温室预热遇冷中"
                            }
                            else if(this.comdata.htwaitready === 1 && this.comdata.ltwaitready === 0)
                            {
                                this.stateStr = "高温室预热中"
                            }
                            else if(this.comdata.htwaitready === 0 && this.comdata.ltwaitready === 1)
                            {
                                this.stateStr = "低温室遇冷中"
                            }
                            else
                            {
                                this.stateStr = "低温室等待"
                            }
                        }
                        else
                        {
                            if(this.comdata.htwaitready === 1 && this.comdata.ltwaitready === 1)
                            {
                                this.stateStr = "高低温室预热遇冷中"
                            }
                            else if(this.comdata.htwaitready === 1 && this.comdata.ltwaitready === 0)
                            {
                                this.stateStr = "高温室预热中"
                            }
                            else if(this.comdata.htwaitready === 0 && this.comdata.ltwaitready === 1)
                            {
                                this.stateStr = "低温室遇冷中"
                            }
                            else
                            {
                                this.stateStr = "高温室等待"
                            }
                        }
                    }
                    else if(this.comdata.devstate === 3)
                    {

                        this.stateStr = this.comdata.despos === 2 ? "高温室程序暂停中" : this.comdata.despos === 1 ? "常温室程序暂停中" : "低温室程序暂停中"
                    }
                }
                else
                {
                    if(this.comdata.frostwaitready === 1)
                    {
                        this.stateStr = "设备除霜等待"
                    }
                    else
                    {
                        this.stateStr = "设备除霜中"
                    }

                }
            }
            else if(this.comdata.devmode === 0)
            {
                if (this.comdata.devstate === 2)
                {
                    this.stateStr = "高温室运行"
                }
                else if(this.comdata.devstate == 4)
                {
                    if(this.comdata.htwaitready === 1)
                    {
                        this.stateStr = "高温室预热中";
                    }
                    else
                    {
                        this.stateStr = "高温室等待";
                    }
                }
                else if(this.comdata.devstate === 3)
                {

                    this.stateStr = "高温室暂停中"
                }
            }
            else{
                if (this.comdata.devstate === 2)
                {
                    this.stateStr = "低温室运行"
                }
                else if(this.comdata.devstate === 4)
                {
                    if(this.comdata.ltwaitready === 1)
                    {
                        this.stateStr = "低温室预冷中"
                    }
                    else
                    {
                        this.stateStr = "低温室等待"
                    }
                }
                else if(this.comdata.devstate === 3)
                {

                    this.stateStr = "低温室暂停中"
                }
            }
        }
        else
        {
            this.stateStr = "设备待机中"
        }
      }
      else{
          this.stateStr = "设备报警中"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 0.156vw;
  &:last-child {
    margin-bottom: 0.156vw;
  }
}
.el-col {
  border-radius: 0.208vw;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
</style>

<style lang="scss" scoped>
.common_detail {
  //width: 20vw;
  padding: 0.156vw;
  border: 0.052vw solid lightseagreen;
  border-radius: 0.26vw;

  .detail_devmode_label,
  .detail_devstate_label,
  .detail_localremote_label,
  .detail_progno_label,
  .detail_segno_label,
  .detail_loopno_label,
  .detail_outerloop_label,
  .detail_innerloop_label,
  .detail_segruntime_label,
  .detail_segsettime_label,
  .detail_totalruntime_label,
  .detail_fixsettime_label,
  .detail_prefinish_label,
  .detail_refreshtime_label {
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 0.833vw;
    min-height: 1.563vw;
    max-height: 1.563vw;
    text-align: center;
    border-radius: 0.26vw;
  }
  .detail_devmode_value,
  .detail_devstate_value,
  .detail_localremote_value,
  .detail_progno_value,
  .detail_segno_value,
  .detail_loopno_value,
  .detail_outerloop_value,
  .detail_innerloop_value,
  .detail_segruntime_value,
  .detail_segsettime_value,
  .detail_totalruntime_value,
  .detail_fixsettime_value,
  .detail_prefinish_value,
  .detail_refreshtime_value {
    background-color: #1f1f1f;
    color: #dbe276;
    border-radius: 0.26vw;
    border: 0.052vw solid brown;
    min-height: 1.563vw;
    max-height: 1.563vw;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 0.833vw;
  }

  .detail_prefinish_value,
  .detail_totalruntime_value {
    padding-left: 0.521vw 0.104vw;
    background-color: green;
    color: white;
  }

  .detail_refreshtime_value {
    padding-left: 0.521vw 0.104vw;
    background-color: orangered;
    color: white;
  }

  .detail_devmode_value {
    background-color: blue;
    color: white;
  }

  .detail_devstate_value {
    background-color: purple;
    color: white;
  }
}

input[type="number"] {
  border: 0.052vw solid;
  border-color: #eee #ccc #ccc #eee;
  color: #000;
  font-weight: bold;
  background: #f5f5f5;
}
</style>
