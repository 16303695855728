<template>
	<div class="compressor_info">
        <el-tabs tab-position="top">
            <el-tab-pane label="机组1">
                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404吸气压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-green-dark">{{parseFloat(compressordata.g1lp404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">Bar</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404排气压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-green-dark">{{parseFloat(compressordata.g1hp404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">Bar</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404吸气温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-black-dark">{{parseFloat(compressordata.g1lt404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">℃</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404排气温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-black-dark">{{parseFloat(compressordata.g1ht404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">℃</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404进蒸发器压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-blue-dark">{{parseFloat(compressordata.g1expinp404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">Bar</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404出蒸发器压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-blue-dark">{{parseFloat(compressordata.g1expoutp404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">Bar</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404进蒸发器温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-red-dark">{{parseFloat(compressordata.g1expint404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404出蒸发器温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-red-dark">{{parseFloat(compressordata.g1expoutt404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23吸气压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-blue-dark">{{parseFloat(compressordata.g1lp23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23排气压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-blue-dark">{{parseFloat(compressordata.g1hp23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23吸气温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-orange-dark">{{parseFloat(compressordata.g1lt23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23排气温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-orange-dark">{{parseFloat(compressordata.g1ht23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23进蒸发器压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-black-dark">{{parseFloat(compressordata.g1expinp23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23出蒸发器压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-black-dark">{{parseFloat(compressordata.g1expoutp23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23进蒸发器温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-green-dark">{{parseFloat(compressordata.g1expint23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23出蒸发器温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-green-dark">{{parseFloat(compressordata.g1expoutt23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>
            </el-tab-pane>

            <el-tab-pane label="机组2">
                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404吸气压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-green-dark">{{parseFloat(compressordata.g1lp404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">Bar</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404排气压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-green-dark">{{parseFloat(compressordata.g1hp404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">Bar</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404吸气温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-black-dark">{{parseFloat(compressordata.g2lt404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">℃</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404排气温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-black-dark">{{parseFloat(compressordata.g2ht404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">℃</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404进蒸发器压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-blue-dark">{{parseFloat(compressordata.g2expinp404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">Bar</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404出蒸发器压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-blue-dark">{{parseFloat(compressordata.g2expoutp404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">Bar</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404进蒸发器温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-red-dark">{{parseFloat(compressordata.g2expint404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404出蒸发器温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-red-dark">{{parseFloat(compressordata.g2expoutt404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23吸气压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-blue-dark">{{parseFloat(compressordata.g2lp23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23排气压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-blue-dark">{{parseFloat(compressordata.g2hp23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23吸气温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-orange-dark">{{parseFloat(compressordata.g2lt23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23排气温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-orange-dark">{{parseFloat(compressordata.g2ht23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23进蒸发器压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-black-dark">{{parseFloat(compressordata.g2expinp23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23出蒸发器压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-black-dark">{{parseFloat(compressordata.g2expoutp23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23进蒸发器温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-green-dark">{{parseFloat(compressordata.g2expint23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23出蒸发器温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-green-dark">{{parseFloat(compressordata.g2expoutt23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>
            </el-tab-pane>

            <el-tab-pane label="机组3">
                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404吸气压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-green-dark">{{parseFloat(compressordata.g3lp404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">Bar</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404排气压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-green-dark">{{parseFloat(compressordata.g3hp404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">Bar</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404吸气温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-black-dark">{{parseFloat(compressordata.g3lt404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">℃</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404排气温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-black-dark">{{parseFloat(compressordata.g3ht404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">℃</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404进蒸发器压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-blue-dark">{{parseFloat(compressordata.g3expinp404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">Bar</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404出蒸发器压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-blue-dark">{{parseFloat(compressordata.g3expoutp404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">Bar</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404进蒸发器温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-red-dark">{{parseFloat(compressordata.g3expint404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404出蒸发器温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-red-dark">{{parseFloat(compressordata.g3expoutt404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23吸气压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-blue-dark">{{parseFloat(compressordata.g3lp23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23排气压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-blue-dark">{{parseFloat(compressordata.g3hp23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23吸气温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-orange-dark">{{parseFloat(compressordata.g3lt23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23排气温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-orange-dark">{{parseFloat(compressordata.g3ht23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23进蒸发器压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-black-dark">{{parseFloat(compressordata.g3expinp23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23出蒸发器压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-black-dark">{{parseFloat(compressordata.g3expoutp23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23进蒸发器温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-green-dark">{{parseFloat(compressordata.g3expint23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23出蒸发器温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-green-dark">{{parseFloat(compressordata.g3expoutt23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>
            </el-tab-pane>

            <el-tab-pane label="机组4">
                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404吸气压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-green-dark">{{parseFloat(compressordata.g4lp404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">Bar</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404排气压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-green-dark">{{parseFloat(compressordata.g4hp404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">Bar</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404吸气温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-black-dark">{{parseFloat(compressordata.g4lt404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">℃</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404排气温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-black-dark">{{parseFloat(compressordata.g4ht404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">℃</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404进蒸发器压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-blue-dark">{{parseFloat(compressordata.g4expinp404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">Bar</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404出蒸发器压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-blue-dark">{{parseFloat(compressordata.g4expoutp404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit  bg-purple-dark">Bar</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404进蒸发器温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-red-dark">{{parseFloat(compressordata.g4expint404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R404出蒸发器温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-red-dark">{{parseFloat(compressordata.g4expoutt404).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23吸气压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-blue-dark">{{parseFloat(compressordata.g4lp23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23排气压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-blue-dark">{{parseFloat(compressordata.g4hp23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23吸气温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-orange-dark">{{parseFloat(compressordata.g4lt23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23排气温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-orange-dark">{{parseFloat(compressordata.g4ht23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23进蒸发器压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-black-dark">{{parseFloat(compressordata.g4expinp23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23出蒸发器压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-black-dark">{{parseFloat(compressordata.g4expoutp23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23进蒸发器温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-green-dark">{{parseFloat(compressordata.g4expint23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">R23出蒸发器温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-green-dark">{{parseFloat(compressordata.g4expoutt23).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>
            </el-tab-pane>

            <el-tab-pane label="除湿机1">
                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">吸气压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-green-dark">{{parseFloat(compressordata.h1lp).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">排气压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-green-dark">{{parseFloat(compressordata.h1hp).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">吸气温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-black-dark">{{parseFloat(compressordata.h1lt).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">排气温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-black-dark">{{parseFloat(compressordata.h1ht).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">进蒸发器压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-orange-dark">{{parseFloat(compressordata.h1expinp).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">出蒸发器压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-orange-dark">{{parseFloat(compressordata.h1expoutp).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">进蒸发器温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-blue-dark">{{parseFloat(compressordata.h1expint).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">出蒸发器温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-blue-dark">{{parseFloat(compressordata.h1expoutt).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>
            </el-tab-pane>

            <el-tab-pane label="除湿机2">
                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">吸气压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-green-dark">{{parseFloat(compressordata.h2lp).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">排气压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-green-dark">{{parseFloat(compressordata.h2hp).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">吸气温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-black-dark">{{parseFloat(compressordata.h2lt).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">排气温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-black-dark">{{parseFloat(compressordata.h2ht).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">进蒸发器压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-blue-dark">{{parseFloat(compressordata.h2expinp).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">出蒸发器压力</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-blue-dark">{{parseFloat(compressordata.h2expoutp).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">Bar</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>

                <el-row :span="24" :gutter="10">
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">进蒸发器温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-orange-dark">{{parseFloat(compressordata.h2expint).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="5"><div class="group1_4_label bg-purple-light">出蒸发器温度</div></el-col>
                    <el-col :span="2"><div class="group1_4_value bg-orange-dark">{{parseFloat(compressordata.h2expoutt).toFixed(1)}}</div></el-col>
                    <el-col :span="2"><div class="group1_4_unit bg-purple-dark">℃</div></el-col>
                    <el-col :span="3"><div class="group1_4_label"></div></el-col>
                </el-row>
            </el-tab-pane>
        </el-tabs>
        
    </div>
</template>

<script>
	export default {
		props: {
			compressordata: {}
		},
		watch: {
            windowHeight (val) {
            let that = this;
            console.log("实时屏幕高度：", val, that.windowHeight);
            this.refreshStyle()
            },
            windowWidth (val) {
            let that = this;
            console.log("实时屏幕宽度：", val, that.windowHeight);
            this.refreshStyle()
            },
            compressordata: {
            immediate: true, // 很重要！！！
            handler (val) {
                //console.log('compressordata:', val)
                this.refreshStyle()
            }
            }
        },
        computed: {
            windowWidth () {
            return this.$store.state.tab.windowWidth
            },
            windowHeight () {
            return this.$store.state.tab.windowHeight
            }
        },
        methods: {
            refreshStyle() {
                var CompressorInfo = document.querySelectorAll('.compressor_info')
                for(var i = 0; i < CompressorInfo.length; i++)
                {
                    CompressorInfo[i].style.height = 55 + 'vh'
                }
            }
        }
	}
</script>

<style lang="scss" scoped>
	.el-row {
    margin-bottom: 0.156vw;
    &:last-child {
      margin-bottom: 0.156vw;
    }
  }
  .el-col {
    border-radius: 0.208vw;
  }
  .bg-purple-dark {
    background: transparent;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: transparent;
  }
  .bg-green-dark {
    background: darkgreen;
    color: white;
  }
  .bg-black-dark {
    background-color: #1F1F1F;
    color: #DBE276;
  }
  .bg-orange-dark {
    background: orangered;
    color: white;
  }
  .bg-blue-dark {
    background-color: darkcyan;
    color: white;
  }

  .bg-red-dark {
    background: red;
    color: white;
  }
  .bg-violet-dark {
    background-color: violet;
    color: white;
  }
</style>

<style lang="scss" scoped>
    
.compressor_info {
	padding: 0.156vw;
	border: 0.052vw solid #ccc;
	
    .group1_4_label,
    .group1_4_unit {
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 0.833vw;
        color: #fff;
        min-height: 1.563vw;
        max-height: 2.083vw;
        text-align: center;
        border-radius: 0.26vw;
    }
    .group1_4_value {
        border-radius: 0.26vw;
        border: 0.052vw solid brown;
        min-height: 1.563vw;
        max-height: 2.083vw;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 0.833vw;
    }    
}

input[type="number"]{
    border: 0.052vw solid; border-color: #EEE #CCC #CCC #EEE;
    color: #000; font-weight: bold; background: #F5F5F5;
}
	
</style>