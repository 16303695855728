<template>
  <div class="startordersetting">
        <el-descriptions :style="objectStyle" title="预约启动" :column="3" border>
            <el-descriptions-item label="年" label-class-name="my-year-label" content-class-name="my-year-content">{{startorderinfo.year}}</el-descriptions-item>
            <el-descriptions-item label="月" label-class-name="my-month-label" content-class-name="my-month-content">{{startorderinfo.month}}</el-descriptions-item>
            <el-descriptions-item label="日" label-class-name="my-day-label" content-class-name="my-day-content">{{startorderinfo.day}}</el-descriptions-item>
            <el-descriptions-item label="时" label-class-name="my-hour-label" content-class-name="my-hour-content">{{startorderinfo.hour}}</el-descriptions-item>
            <el-descriptions-item label="分" label-class-name="my-min-label" content-class-name="my-min-content">{{startorderinfo.min}}</el-descriptions-item>
            <el-descriptions-item label="是否开启预约" label-class-name="my-order-label" content-class-name="my-order-content">{{startorderinfo.start === true ? '已开启' : '未开启'}}</el-descriptions-item>
        </el-descriptions>
    </div>
</template>

<script>
export default {
  data() {
      return {
         objectStyle: {
           width:  this.windowWidth
         }
      }
  },
  props: {
    startorderinfo: {}
  },
  watch: {
     windowHeight (val) {
      let that = this;
      console.log("实时屏幕高度：", val, that.windowHeight);
      setTimeout(()=> {
           this.refreshStyle()
        }, 100)
    },
    windowWidth (val) {
      let that = this;
      console.log("实时屏幕宽度：", val, that.windowHeight);
      setTimeout(()=> {
           this.refreshStyle()
        }, 100)
    },
    
    startorderinfo: {
      immediate: true, // 很重要！！！
      handler (val) {
        //console.log('refamenddata:', val)
        setTimeout(()=> {
           this.refreshStyle()
        }, 100)
        
      }
    }
  },
  computed: {
    windowWidth () {
      return this.$store.state.tab.windowWidth
    },
    windowHeight () {
      return this.$store.state.tab.windowHeight
    }
  },
  methods: {
    refreshStyle() {
      var startorder = document.querySelector('.startordersetting')
      startorder.style.height = 33 + 'vh'

      this.objectStyle.width = 60 + 'vw'
      //console.log('this.objectStyle.width', this.objectStyle.width)
    }
  }
}
</script>


<style lang="scss" scoped>
.startordersetting ::v-deep {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .my-year-label,
    .my-month-label,
    .my-day-label,
    .my-hour-label,
    .my-min-label,
    .my-order-label {
      color: #1f1f1f;
      text-align: center;
  }

  .el-descriptions {
   
    color: lemonchiffon;
}

  .my-year-content {
    color:orangered;
    text-align: center;
  }

  .my-month-content {
    color:darkcyan;
    text-align: center;
  }

  .my-day-content {
    color:green;
    text-align: center;
  }

  .my-hour-content {
    color:brown;
    text-align: center;
  }

  .my-min-content {
    color: blue;
    text-align: center;
  }

  .my-order-content {
    color: violet;
    text-align: center;
  }
}
</style>