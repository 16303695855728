<template>
  <div class="amend">
      <div class="channel_amend">
        <el-row :span="24" :gutter="10">
           <el-col :span="2"><div class="channel_name_label bg-purple-light"></div></el-col>
            <el-col :span="4"><div class="channel_name_label bg-purple-light">通道名称</div></el-col>
            <el-col :span="2"><div class="channel_name_value">{{chdata.chname}}</div></el-col>
            <el-col :span="3"><div class="channel_id_label bg-purple-light">通道ID</div></el-col>
            <el-col :span="1"><div class="channel_id_value">{{chdata.id}}</div></el-col>
            <el-col :span="3"><div class="channel_unit_label bg-purple-light">通道单位</div></el-col>
            <el-col :span="1"><div class="channel_unit_value">{{chdata.chunit}}</div></el-col>
            <el-col :span="3"><div class="channel_offset_label bg-purple-light">平移修正</div></el-col>
            <el-col :span="2"><div class="channel_offset_value">{{amenddata[chdata.id - 1].offset.toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="channel_name_label bg-purple-light"></div></el-col>
        </el-row>
      
        <el-row :span="24" :gutter="10">
            <el-col :span="2"><div class="channel_district_label bg-purple-light"></div></el-col>
            <el-col :span="4"><div class="channel_district_label bg-purple-light">{{chdata.chname}}区间</div></el-col>
            <el-col :span="3"><div class="channel_range_value">{{amenddata[chdata.id - 1].range1.toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="channel_range_value">{{amenddata[chdata.id - 1].range2.toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="channel_range_value">{{amenddata[chdata.id - 1].range3.toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="channel_range_value">{{amenddata[chdata.id - 1].range4.toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="channel_range_value">{{amenddata[chdata.id - 1].range5.toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="channel_district_label bg-purple-light"></div></el-col>
        </el-row>
      
      
        <el-row :span="24" :gutter="10">
            <el-col :span="2"><div class="channel_adjust_label bg-purple-light"></div></el-col>
            <el-col :span="4"><div class="channel_adjust_label bg-purple-light">线性修正</div></el-col>
            <el-col :span="3"><div class="channel_amend_value">{{amenddata[chdata.id - 1].amend1.toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="channel_amend_value">{{amenddata[chdata.id - 1].amend2.toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="channel_amend_value">{{amenddata[chdata.id - 1].amend3.toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="channel_amend_value">{{amenddata[chdata.id - 1].amend4.toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="channel_amend_value">{{amenddata[chdata.id - 1].amend5.toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="channel_adjust_label bg-purple-light"></div></el-col>
        </el-row>
      </div>
      <div class="channel_ref_amend">
        <el-row span="24" :gutter="10" v-show="(chdata.chtype === 0 && chinfo.length > 1 && chinfo.length > chdata.id && chinfo[chdata.id].chctlflag === true && (chinfo[chdata.id].chtype === 1 || chinfo[chdata.id].chtype === 2))">
            <el-col><div class="notify_label bg-purple-dark">注意：做湿度时请修正这里,上面的是单做温度时修正的</div></el-col>
        </el-row>
        <el-row span="24" :gutter="10" v-show="((chdata.chtype === 1 || chdata.chtype === 2) && chinfo.length >= chdata.id && chinfo[chdata.id - 1].chctlflag === true)">
            <el-col><div class="notify_label bg-purple-dark">注意:该功能是以干球温度位参照,以应对某一个温度点湿度整体偏高或偏低的情况</div></el-col>
        </el-row>
        <el-row :span="24" :gutter="10" v-show="(chdata.chtype === 0 && chinfo.length > 1 && chinfo.length > chdata.id && chinfo[chdata.id].chctlflag === true && (chinfo[chdata.id].chtype === 1 || chinfo[chdata.id].chtype === 2)) || (chdata.chtype === 1 && chinfo.length >= chdata.id && chinfo[chdata.id - 1].chctlflag === true)">
            <el-col :span="2"><div class="channel_name_label bg-purple-light"></div></el-col>
            <el-col :span="4"><div class="channel_name_label bg-purple-light">通道名称</div></el-col>
            <el-col :span="2"><div class="channel_name_value">{{chdata.chname}}</div></el-col>
            <el-col :span="3"><div class="channel_id_label bg-purple-light">通道ID</div></el-col>
            <el-col :span="1"><div class="channel_id_value">{{chdata.id}}</div></el-col>
            <el-col :span="3"><div class="channel_unit_label bg-purple-light">通道单位</div></el-col>
            <el-col :span="1"><div class="channel_unit_value">{{chdata.chunit}}</div></el-col>
            <el-col :span="3"><div class="channel_offset_label bg-purple-light">平移修正</div></el-col>
            <el-col :span="2"><div class="channel_offset_value">{{refamenddata[chdata.id - 1].offset.toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="channel_name_label bg-purple-light"></div></el-col>
        </el-row>

        <el-row :span="24" :gutter="10" v-show="(chdata.chtype === 0 && chinfo.length > 1 && chinfo.length > chdata.id && chinfo[chdata.id].chctlflag === true && (chinfo[chdata.id].chtype === 1 || chinfo[chdata.id].chtype === 2)) || (chdata.chtype === 1 && chinfo.length >= chdata.id && chinfo[chdata.id - 1].chctlflag === true)">
            <el-col :span="2"><div class="channel_district_label bg-purple-light"></div></el-col>
            <el-col :span="4"><div class="channel_district_label bg-purple-light">{{chdata.chname}}区间</div></el-col>
            <el-col :span="3"><div class="channel_range_value">{{refamenddata[chdata.id - 1].range1.toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="channel_range_value">{{refamenddata[chdata.id - 1].range2.toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="channel_range_value">{{refamenddata[chdata.id - 1].range3.toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="channel_range_value">{{refamenddata[chdata.id - 1].range4.toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="channel_range_value">{{refamenddata[chdata.id - 1].range5.toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="channel_district_label bg-purple-light"></div></el-col>
        </el-row>

        <el-row :span="24" :gutter="10" v-show="(chdata.chtype === 0 && chinfo.length > 1 && chinfo.length > chdata.id && chinfo[chdata.id].chctlflag === true && (chinfo[chdata.id].chtype === 1 || chinfo[chdata.id].chtype === 2)) || (chdata.chtype === 1 && chinfo.length >= chdata.id && chinfo[chdata.id - 1].chctlflag === true) ">
            <el-col :span="2"><div class="channel_adjust_label bg-purple-light"></div></el-col>
            <el-col :span="4"><div class="channel_adjust_label bg-purple-light">线性修正</div></el-col>
            <el-col :span="3"><div class="channel_amend_value">{{refamenddata[chdata.id - 1].amend1.toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="channel_amend_value">{{refamenddata[chdata.id - 1].amend2.toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="channel_amend_value">{{refamenddata[chdata.id - 1].amend3.toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="channel_amend_value">{{refamenddata[chdata.id - 1].amend4.toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="channel_amend_value">{{refamenddata[chdata.id - 1].amend5.toFixed(1)}}</div></el-col>
            <el-col :span="3"><div class="channel_adjust_label bg-purple-light"></div></el-col>
        </el-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chdata: {},
    amenddata: [],
    refamenddata: [],
    chinfo:[]
  },
  watch: {
     windowHeight (val) {
      let that = this;
      console.log("实时屏幕高度：", val, that.windowHeight);
      this.refreshStyle()
    },
    windowWidth (val) {
      let that = this;
      console.log("实时屏幕宽度：", val, that.windowHeight);
      this.refreshStyle()
    },
    
    refamenddata: {
      immediate: true, // 很重要！！！
      handler (val) {
        //console.log('refamenddata:', val)
        setTimeout(()=> {
           this.refreshStyle()
        }, 100)
        
      }
    },
    amenddata: {
      immediate: true, // 很重要！！！
      handler (val) {
        //console.log('amenddata:', val)
        setTimeout(()=> {
           this.refreshStyle()
        }, 100)
      }
    },
  },
  computed: {
    windowWidth () {
      return this.$store.state.tab.windowWidth
    },
    windowHeight () {
      return this.$store.state.tab.windowHeight
    }
  },
  methods: {
    refreshStyle() {
      var amend = document.querySelector('.amend')
      amend.style.height = this.chinfo.length > 0 ? (100 / parseFloat(this.chinfo.length) + 'vh') : 50 + 'vh'
      
      var elRow = document.querySelectorAll('.el-row')
      for(var i = 0; i < elRow.length; i++)
      {
          elRow[i].style.marginBottom = 100 / 120 + 'vh'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 1.042vw;
  &:last-child {
    margin-bottom: 0.156vw;
  }
}
.el-col {
  border-radius: 0.208vw;
}
.bg-purple-dark {
  background: transparent;
}
.bg-purple {
  background: transparent;
}
.bg-purple-light {
  background: transparent;
}
</style>

<style lang="scss" scoped>

.amend {
    margin: 0.521vw;
    .channel_amend,
    .channel_ref_amend {
      margin: 0.521vw;
      padding: 0.521vw;
      border-radius: 0.26vw;
      min-height: 1.563vw;

      .channel_name_label,
      .channel_id_label,
      .channel_unit_label,
      .channel_offset_label,
      .channel_district_label,
      .channel_adjust_label {
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 0.833vw;
        color: #fff;
        min-height: 1.563vw;
        max-height: 1.563vw;
        text-align: center;
        border-radius: 0.26vw;
      }
      .channel_name_value,
      .channel_id_value,
      .channel_unit_value,
      .channel_offset_value,
      .channel_range_value,
      .channel_amend_value {
        background-color: #1f1f1f;
        color: #dbe276;
        border-radius: 0.26vw;
        border: 0.052vw solid brown;
        min-height: 1.563vw;
        max-height: 1.563vw;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 0.833vw;
      }

      .notify_label {
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 0.833vw;
        color: #fff;
        min-height: 1.563vw;
        max-height: 1.563vw;
        text-align: center;
        border-radius: 0.26vw;
      }

      .channel_id_value,
      .channel_offset_value {
        padding-left: 0.521vw 0.104vw;
        background-color: darkred;
        color: white;
      }

      .channel_range_value {
        padding-left: 0.521vw 0.104vw;
        background-color: green;
        color: white;
      }

      .channel_amend_value {
        background-color: darkcyan;
        color: white;
      }
    }
}

input[type="number"] {
  border: 0.052vw solid;
  border-color: #eee #ccc #ccc #eee;
  color: #000;
  font-weight: bold;
  background: #f5f5f5;
}
</style>